import { useContext, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { EmiContext } from "state/Emi/Emi";
import withPaymentCard from "hoc/withPaymentCard";

import {
    EmiOptionsContainer,
    EmiPlanContainer,
    RadioBtn,
    RadioBtnLabel,
} from "./styled";
import { P2, P4 } from "components/Styled/Text";
import Card from "components/Card/Card.js";

const EmiTypeBank = () => {
    const { bankCode, orderId } = useParams();
    const { pathname } = useLocation();
    const { options, setSelectedEmiCode, selectedEmiCode } = useContext(EmiContext);
    const emiType = pathname.split('/').slice(-2)[0];
    const bottomRef = useRef();
    const navigate = useNavigate();

    const emi = options[emiType]?.find(emi => emi.bank_code === bankCode);

    useEffect(() => {
        if (!options) {
            navigate(`/pay/${orderId}/emi`);
        }

        return () => setSelectedEmiCode('');
    }, []) //eslint-disable-line

    return (
        <div>
            <EmiOptionsContainer>
                <P2>EMI Plans</P2>
                {
                    emi?.tenure_options.map(tenure => (
                        <EmiTenure key={tenure.bank_code} data={tenure} selectedEmi={{ selectedEmiCode, setSelectedEmiCode }} />
                    ))
                }
            </EmiOptionsContainer>
            {selectedEmiCode && <Card isSelected={true} isEmi={true} />}
            <div ref={bottomRef} />
        </div>
    )
}

const EmiTenure = ({ data, selectedEmi: { selectedEmiCode, setSelectedEmiCode } }) => {
    const handleOnChange = ({ target }) => {
        setSelectedEmiCode(target.value)
    }

    return (
        <EmiPlanContainer>
            <RadioBtn style={{ backgroundColor: 'green' }} type="radio" name="radio" value={data.bank_code} onChange={handleOnChange} checked={selectedEmiCode === data.bank_code} />
            <RadioBtnLabel onClick={() => setSelectedEmiCode(data.bank_code)}>
                <P2 className="mb-0">{`Pay INR ${data.amount} x ${data.tenure}`}</P2>
                <P4>{`Total interest charged = INR ${data.emi_interest_paid}, Convenience Fee = INR ${data.bank_charge}`}</P4>
            </RadioBtnLabel>
        </EmiPlanContainer>
    )
}

export default withPaymentCard(EmiTypeBank);