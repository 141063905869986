import { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from 'hooks/useFetch';
import { updateGroupUi, getOrderIdFromUrl, localSt } from 'helper/misc';
import { loader } from 'helper/misc';
import toast, { useToasterStore } from 'react-hot-toast';
import { NavigationContext } from 'state/Navigation/Navigation';
import { BasicContext } from 'state/Basic/Basic';
import { calculateConvenienceFeeAmount } from '../../helper/convenienceFee';
import {Engine} from 'json-rules-engine';

export const InfoContext = createContext('info');

const InfoProvider = ({ children }) => {
    const location = useLocation();
    const [group, setGroup] = useState("");
    const [paymentMethods, setPaymentMethods] = useState({});
    const [convenienceFeeRuleEngine, setConvenienceFeeRuleEngine] = useState(null);
    const [order, setOrder] = useState('');
    const [convenienceFee, setConvenienceFee] = useState(0);
    const { getGroupInfo, getPaymentMethods, getOrderInfo } = useFetch();
    const { toasts } = useToasterStore();
    const { setIsAmountBreakupOpen } = useContext(NavigationContext);
    const { apiUrl } = useContext(BasicContext);

    const paymentMethod = location.pathname.split('/')[3];
    const apiConvenienceFee = paymentMethods.convenience_fee;
    
    useEffect(() => {
        const isPayment = location.pathname.split('/')[1] === 'pay';
        let isMounted = true;

        if (isPayment) {
            if (apiUrl && isMounted) {
                const orderId = getOrderIdFromUrl(location.pathname);
                localSt.addOrderId(orderId)
                getInfo();
            }
            return () => isMounted = false
        }
        hideLoader()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiUrl]);

    useEffect(() => {
        setIsAmountBreakupOpen(!!convenienceFee);
    }, [convenienceFee])

    useEffect(() => {
        // Remove all toasts
        !!toasts && toast.dismiss();
        if (!paymentMethod) {
            setConvenienceFee(0);
            setIsAmountBreakupOpen(false)
        }
        convenienceFeeRuleEngine && updateConvenienceFees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, apiConvenienceFee])

    const hideLoader = () => {
        setTimeout(() => {
            loader.hide()
        }, 200)
    }

    const updateConvenienceFees = () => {
        const facts = {
            amount: order.amount/100
        }
        switch (paymentMethod) {
            case 'netbanking':
                facts.method = 'netbanking';
                break;
            case 'cards':
                return;
            case 'wallet':
                facts.method = 'wallet';
                break;
            case 'upi':
                facts.method = 'upi';
                break;
            case 'scanAndPay':
                facts.method = 'scanAndPay';
                break;
        }
        convenienceFeeRuleEngine?.run(facts)
        .then(({events}) => {
            events.map(event => {
                const fee = calculateConvenienceFeeAmount(event.type, event.params?.value, order.amount);
                if (!fee) {
                    return 
                }
                setConvenienceFee(fee);
            })
        }).catch(e => console.log('error occured'))
    }

    const getInfo = async () => {
        Promise.all([updateGroupInfo(), updatePaymentMethods(), updateOrderInfo()])
            .then(() => hideLoader())
            .catch(() => hideLoader())
    }

    const updateGroupInfo = async () => {
        const info = await getGroupInfo();
        const updatedGroupInfo = {
            ...group,
            ...info.data
        }
        setGroup(updatedGroupInfo);
        updateGroupUi(updatedGroupInfo)
    }

    const updatePaymentMethods = async () => {
        const methods = await getPaymentMethods();
        if (!methods?.status) {
            console.log('throwing error')
            throw Error
        }
        if (methods.data.convenience_fee?.rules) {
            const engine = new Engine(methods.data.convenience_fee.rules.decisions, {allowUndefinedFacts: true});
            setConvenienceFeeRuleEngine(engine)
        }
        setPaymentMethods(methods.data)
    }

    const updateOrderInfo = async () => {
        const orderInfo = await getOrderInfo();
        setOrder(orderInfo?.data);
    }

    const values = {
        group,
        setGroup,
        paymentMethods,
        setPaymentMethods,
        order,
        convenienceFee,
        setConvenienceFee,
        convenienceFeeRuleEngine,
        setIsAmountBreakupOpen
    }

    return (
        <InfoContext.Provider value={values}>
            {children}
        </InfoContext.Provider>
    )
}

export default InfoProvider;