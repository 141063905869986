import styled from "styled-components";
import { PrimaryPayBtn } from "../../components/Styled/Buttons";

export const PaymentOptionsContainer = styled.div`
    max-height: 76vh;
`

export const ProceedBtn = styled(PrimaryPayBtn)`
    margin-left: 40px;
    padding: 1px 8px;
`