import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { P4 } from "../Styled/Text";
import PaymentOption from "../Payment/PaymentOption/PaymentOption";
import SelectedPayment from "../../layout/SelectedPayment/SelectedPayment";
import { SelectedPaymentSubContainer } from "../../layout/SelectedPayment/SelectedPayment";
import { InfoContext } from "state/Info/Info";

import { ProceedBtn } from "../../routes/PaymentOptions/styled";

import { paymentMethodsToDisplay } from "./services";

const PaymentOptions = () => {
  const [selectedMethod, setSelectedMethod] = useState("");
  const { paymentMethods: apiPaymentMethods, order } = useContext(InfoContext);
  const navigate = useNavigate();

  const handleOnClick = ({ route }) => navigate(route);
  const methodsToDisplay = paymentMethodsToDisplay(apiPaymentMethods, order.is_test);

  return (
    methodsToDisplay.map((option, i) => (
      selectedMethod !== option.name ? <PaymentOption
        key={i}
        name={option.name}
        subHeader={option.subHeader}
        icon={{left: option.icon}}
        code={`${option.code}`}
        onClick={() => { handleOnClick(option) }}
      /> : (
        <SelectedPayment key={i} name={option.name} icon={option.image} code={option.code} onClose={() => setSelectedMethod("")}>
          <SelectedPaymentContainer />
        </SelectedPayment>
      )
    ))
  )
}

const SelectedPaymentContainer = () => (
  <SelectedPaymentSubContainer>
    <P4>Keep mobile handy to complete payment with OTP</P4>
    <ProceedBtn>PROCEED</ProceedBtn>
  </SelectedPaymentSubContainer>
)

export default PaymentOptions