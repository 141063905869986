import styled from "styled-components";

export const P1 = styled.p`
    font-size: 14px;
`

export const P2 = styled.p`
    font-size: 13px;
`

export const P3 = styled.p`
    font-size: 12px;
    color: #8E8E8E;
    margin-bottom: 0;
`

export const P4 = styled.p`
    font-size: 11px;
`

export const P5 = styled.p`
    font-size: 10px;
`

export const ErrorText = styled.p`
    font-size: 12px;
    padding-top: 5px;
    color: ${props => props.theme.colors.danger};
    margin-bottom: 0;
`

export const SuccessText = styled.p`
    font-size: 12px;
    color: ${props => props.theme.colors.success};
    margin-bottom: 0;
`

export const CategoryHeading = styled.p`
    font-size: 11px;
	color: #8E8E8E;
    padding: 15px 0 15px 35px;
	text-transform: uppercase;
`