import styled from "styled-components";

export const PaymentForm = styled.div`
    margin-top: 40px;
    margin-left: 26px;
`

export const Input1 = styled.input`
    border-radius: 4px;
    border: 1px #ccc solid;
    height: 40px;
    padding-left: 10px;
    width: 100%;
	transition: all 400ms ease-in-out;
    border-color: ${props => props.isError && props.theme.colors.danger};
    &:focus {
        border-color: ${props => !props.isError && '#2e2e2e'};
        outline: none;
    }
`

export const MobileNoConatiner = styled.div`
    padding-bottom: 19px;
`
