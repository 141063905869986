import { Input1 } from 'components/Styled/Misc';
import styled from 'styled-components';


export const InputContainer = styled.div`
    border: 1px #ccc solid;
    border-radius: 4px;
    background-color: white;
    padding-right: 5px;
`

export const Input = styled(Input1)`
    border: 0px;
    width: 90%;
`