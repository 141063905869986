import { createContext, useState, createRef, useEffect, useContext } from "react";
import { InfoContext } from "state/Info/Info";

export const CardContext = createContext();

const CardProvider = ({children}) => {
    const {setConvenienceFee, convenienceFeeRuleEngine, order, setIsAmountBreakupOpen} = useContext(InfoContext)
    const [cardNo, setCardNo] = useState('');
    const [cardCategory, setCardCategory] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [name, setName] = useState('');
    const [isCardTypeValid, setIsCardTypeValid] = useState(false);
    const [savedCards, setSavedCards] = useState([]);
    const [savedCardToken, setSavedCardToken] = useState();
    const [showSavedOptions, setShowSavedOptions] = useState(false);

    const [cardBinInfo, setCardBinInfo] = useState('');

    const [cardNoError, setCardNoError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvvError, setCvvError] = useState('');
    const [cardTypeInvalidError, setCardTypeInvalidError] = useState('');

    const cardNoRef = createRef();
    const expiryRef = createRef();
    const cvvRef = createRef();
    const cardNameRef = createRef();

    useEffect(() => {
         updateCardConvenienceFee()
    }, [cardBinInfo])

    const values = {
        // Input Values
        cardNo,
        setCardNo,
        cardCategory,
        setCardCategory,
        expiry,
        setExpiry,
        cvv, 
        setCvv,
        name,
        setName,
        isCardTypeValid, 
        setIsCardTypeValid,
        savedCards,
        setSavedCards,
        savedCardToken,
        setSavedCardToken,
        showSavedOptions,
        setShowSavedOptions,
        // Refs
        cardNoRef,
        expiryRef,
        cvvRef,
        cardNameRef,
        // Errors
        cardNoError,
        setCardNoError,
        expiryError,
        setExpiryError,
        cvvError,
        setCvvError,
        cardTypeInvalidError,
        setCardTypeInvalidError,
        cardBinInfo,
        setCardBinInfo
    }

    const updateCardConvenienceFee = () => {
        if (!cardBinInfo) {
            setConvenienceFee(0);
        }
        if (convenienceFeeRuleEngine && !!cardBinInfo) {
            const facts = {
                amount: order.amount/100,
                method: 'card',
                card_type: cardBinInfo?.cardCategory?.toLowerCase(),
                card_network: cardBinInfo?.cardType?.toLowerCase(),
                bank: cardBinInfo?.issuingBank,
                is_domestic: cardBinInfo?.isDomestic === 'Y' ? "true" : "false"
            }
            convenienceFeeRuleEngine?.run(facts)
            .then(({events}) => {
                events.map(event => {
                    const fee = calculateConvenienceFeeAmount(event.type, event.params?.value);
                    if (!fee) {
                        return 
                    }
                    setConvenienceFee(fee);
                })
            }).catch(e => console.log('error occured'))
        }
    }

    const calculateConvenienceFeeAmount = (type, fee) => {
        if (type === 'percentage') {
            return (order.amount / 10000) * fee;
        }
        if (type === 'flat') {
            return fee
        }
        if (type == 'none') {
            return null
        }
    }

    return (
        <CardContext.Provider value={values}>
            {children}
        </CardContext.Provider>
    )
};

export default CardProvider;