import { createContext, useState } from "react";

export const InputContext = createContext();

const InputProvider = ({ children }) => {
    const [mobileNo, setMobileNo] = useState('');
    const [mobileNoError, setMobileNoError] = useState('');
    const [upiId, setUpiId] = useState('');
    const [selectedUpiHandle, setSelectedUpiHandle] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [consentCheckbox, setConsentCheckbox] = useState(true);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
    // const [emiPlan, setEmiPlan] = useState('');
    // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

    const values = {
        mobileNo,
        setMobileNo,
        mobileNoError,
        setMobileNoError,
        upiId,
        setUpiId,
        selectedUpiHandle,
        setSelectedUpiHandle,
        email,
        setEmail,
        emailError,
        setEmailError,
        consentCheckbox,
        setConsentCheckbox,
        selectedPaymentOption,
        setSelectedPaymentOption,
    }

    return (
        <InputContext.Provider value={values}>
            {children}
        </InputContext.Provider>
    )

}

export default InputProvider;