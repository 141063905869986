import styled from 'styled-components';
import { close } from '../../config/images.js';
import { P2 } from '../../components/Styled/Text.js';
import { DownIndicator } from 'components/Payment/PaymentOption/styled.js';

const SelectedPayment = ({name, icon, code, onClose, isDown, children}) => {
    return (
        <Container data-testid={`selected-option-${code}`}>
            <Header onClick={onClose}>
                <img data-testid={`logo-${code}`} width={20} className='me-2 mb-0' src={icon.left}  onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = icon.altLeft
            }}/>
                <P2 className='me-auto mb-0'>{name}</P2>
                {onClose && !isDown && <img data-testid='close-btn' alt='close' onClick={onClose} className='mb-0' src={close}/>}
                {isDown && <DownIndicator>Currently Down</DownIndicator>}
            </Header>
            {children}
        </Container>
    )
};

const Container = styled.div`
    background-color: #f2f2f2;
    border-left: ${props => `${props.theme.colors.primary} 4px solid`};
    padding: 20px 45px 20px 41px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

export const SelectedPaymentSubContainer = styled.div`
display: flex;
padding: 21px 0 10px 37px;
`

export default SelectedPayment;