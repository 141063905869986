import { Routes, Route } from 'react-router-dom';

import Cards from './Cards/Cards.js';
import PaymentOptions from './PaymentOptions/PaymentOptions.js';
import Qr from './Qr/Qr.js';

import Error500 from 'components/Error/500.js';
import NetBanking from 'components/NetBanking/NetBanking.js';
import Upi from 'components/Upi/Upi.js';
import UpiLoader from 'components/Upi/UpiLoader/UpiLoader.js';
import Wallets from 'components/Wallets/index.js';
import Emi from 'components/Emi/Emi.js';
import EmiType from 'components/Emi/EmiType/EmiType.js';
import EmiTypeBank from 'components/Emi/EmiTypeBank/EmiTypeBank.js';
import TestSimulator from 'components/Modal/TestSim/TestSim.js';

const AllRoutes = () => (
    <Routes>
        <Route path="pay/:orderId">
            <Route index element={<PaymentOptions />} />
            <Route path="netbanking" element={<NetBanking />} />
            <Route path="cards" element={<Cards />} />
            {UpiRoutes}
            <Route path="wallet" element={<Wallets />} />
            <Route path="scanAndPay" element={<Qr />} />
            {EmiRoutes}
            <Route path="error" element={<Error500 />} />
            <Route path="*" element={<Error500 errorMsg="Invalid Path" hideBackBtn={true}/>} />
            <Route path="txn-simulator" element={<TestSimulator/>}/>
        </Route>
        <Route path="error" element={<Error500 />} />
        <Route path="*" element={<Error500 errorMsg="Invalid Path" hideBackBtn={true} />} />
    </Routes>
)

const EmiRoutes = (
    <Route path="emi">
        <Route index element={<Emi />} />
        <Route path="cc">
            <Route index element={<EmiType type="cc" />} />
            <Route path=":bankCode" element={<EmiTypeBank />} />
        </Route>
        <Route path="dc" >
            <Route index element={<EmiType type="dc" />} />
            <Route path=":bankCode" element={<EmiTypeBank />} />
        </Route>
    </Route>
)

const UpiRoutes = (
    <Route path='upi'>
        <Route index element={<Upi />} />
        <Route path='loader' element={<UpiLoader />} />
    </Route>
)

export default AllRoutes