import React from "react";
import './App.css';
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from './layout/Theme';
import {Toaster} from 'react-hot-toast';

import StateProviders from "state/Providers";

import AllRoutes from "./routes/index.js";

const App = () => {
    return (
        <React.StrictMode>
            <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
                <StateProviders>
                    <ThemeProvider>
                        <AllRoutes />
                    </ThemeProvider>
                </StateProviders>
            </BrowserRouter>
            <Toaster position="top-right"/>
        </React.StrictMode>
    )
}

export default App;
