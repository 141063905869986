import { useEffect, useContext } from 'react';
import { BhimFooterContainer} from './styled.js';
import PaymentOption from 'components/Payment/PaymentOption/PaymentOption.js';
import SelectedPayment from 'layout/SelectedPayment/SelectedPayment.js';
import { plusIcon, upiIcon } from 'config/images.js';
import { UpiLogos } from 'routes/Qr/Qr.js';
import SelectedUpi from './SelectedUpi.js';
import withPaymentCard from 'hoc/withPaymentCard.js';

import { NavigationContext } from 'state/Navigation/Navigation.js';
import { InputContext } from 'state/Input/Input.js';
import { InfoContext } from 'state/Info/Info.js';
import { bhimUpiLogo } from 'config/images.js';

import ScanAndPay from './ScanAndPay/ScanAndPay.js';

const Upi = () => {
    const { leftSideCard, setLeftSideCard } = useContext(NavigationContext);
    const { selectedPaymentOption, setSelectedPaymentOption } = useContext(InputContext);
    const { paymentMethods, order } = useContext(InfoContext);

    useEffect(() => {
        return () => setSelectedPaymentOption(''); 
    }, []) // eslint-disable-line

    useEffect(() => {
        const updatedLeftSideCard = {
            ...leftSideCard,
            header: `Enter ${selectedPaymentOption.title || 'any UPI'} ID`
        }
        setLeftSideCard(updatedLeftSideCard)

    }, [selectedPaymentOption]) // eslint-disable-line

    return (
        <div>
            {paymentMethods.scanAndPay && !order.is_test && <ScanAndPay />}
            {
                paymentMethods?.upi?.data.map(paymentOption => {
                    if (paymentOption.code !== selectedPaymentOption.code) {
                        return <PaymentOption
                            key={paymentOption.code}
                            code={paymentOption.code}
                            name={paymentOption.title}
                            icon={{left: paymentOption.image || upiIcon, altLeft: upiIcon}}
                            onClick={() => setSelectedPaymentOption(paymentOption)}
                        />
                    }
                    return (
                        <SelectedPayment
                            key={paymentOption.code}
                            code={paymentOption.code}
                            name={paymentOption.title}
                            icon={{left: paymentOption.image || upiIcon, altLeft: upiIcon}}
                            onClose={() => setSelectedPaymentOption({})}
                        >
                            <SelectedUpi
                                Footer={paymentOption.code === 'bhim' && BhimFooter}
                                upiHandles={paymentOption.upiHandles}
                            />
                        </SelectedPayment>
                    )
                })
            }
            {
                selectedPaymentOption.code !== 'any' ? <PaymentOption
                    code="any"
                    name="Any UPI ID"
                    icon={{left: plusIcon}}
                    onClick={() => setSelectedPaymentOption({ code: 'any', name: 'Any' })}
                /> : <SelectedPayment code="any" name="Any UPI ID" icon={{left: plusIcon}} onClose={() => setSelectedPaymentOption({})}>
                    <SelectedUpi Footer={UpiLogos}/>
                </SelectedPayment>
            }
        </div>
    )
};

const BhimFooter = () => (
    <BhimFooterContainer>
        <img src={bhimUpiLogo} alt="BHIM" />
    </BhimFooterContainer>
)

export default withPaymentCard(Upi)