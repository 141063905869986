import { useContext } from "react";
import { 
  Container, 
  RightSideContainer, 
  Image, 
  PrimaryLink, 
  LeftSideContainer, 
  BrandLogo,
} from "./styled";
import { InfoContext } from "../../state/Info/Info";

import {footerImages} from "config/images";

const Footer = () => {
  const {group} = useContext(InfoContext);

  return (
    <Container className="d-flex flex-column flex-md-row gap-3">
            <LeftSideContainer>
              <BrandLogo alt={group.name} className="mx-2" src={group.logo} />
              {group.links?.privacy && <PrimaryLink href={group.links?.privacy} target="_blank">Privacy Policy</PrimaryLink>}
            </LeftSideContainer>
            <RightSideContainer>
              <FooterLogo src={footerImages.nortonSecured} />
              <FooterLogo src={footerImages.verifiedByVisa} />
              <FooterLogo src={footerImages.masterCardSecure} />
              <FooterLogo src={footerImages.rupay} />
              <FooterLogo src={footerImages.amexSafekey} />
              <FooterLogo src={footerImages.pciDss} />
            </RightSideContainer>
    </Container>
  )
}

const FooterLogo = ({src}) => <Image onError={e => e.target.style.display = 'none'} src={src}/>

export default Footer;