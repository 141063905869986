import styled from 'styled-components'

export const ScanAndPayContainer = styled.div`
    padding: 22px 36px;
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
`

export const ScanPrimaryText = styled.p`
    font-size: 12px;
    color: #2e2e2e;
    margin: 0;
`

export const ScanSecondaryText = styled.p`
    font-size: 10px;
    padding-bottom: 4px;
    color: #8e8e8e;
`

export const GenerateButton = styled.button`
    border: ${props => `solid 1px ${props.theme.colors.secondary}`};
    background: #fff;
    color: ${props => props.theme.colors.secondary};
    width: 133px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 4px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
`