import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks/useFetch';

import { InputContainer, Input } from './styled';
import { NewCardFieldContainer, NewCardInputLabel } from '../styled';
import { ErrorText } from '../../Styled/Text';

import { CARD_CONFIG, VALIDATION_REGEX, ERROR_MSSG } from '../../../config/static';
import { chunk, cardNoValidator } from '../helper';
import { getCardLogo, sanitize, getCardInfo } from '../helper';

import { CardContext } from 'state/Card';
import { EmiContext } from 'state/Emi/Emi';
import { InfoContext } from 'state/Info/Info';

const CardNo = () => {
    let sanitizedCardNo
    const {
        cardNo,
        setCardNo,
        expiryRef,
        cardNoError,
        setCardNoError,
        cardCategory,
        setCardCategory,
        isCardTypeValid,
        setIsCardTypeValid,
        setCardTypeInvalidError,
        setCardBinInfo
    } = useContext(CardContext);
    const [cardType, setCardType] = useState('');
    const { selectedEmiCode } = useContext(EmiContext);
    const { paymentMethods } = useContext(InfoContext);
    const { getBinInfo, checkBinEmiEligibility } = useFetch();
    const { bankCode } = useParams();
    const isEmi = !!selectedEmiCode;

    const separator = CARD_CONFIG.CARD_NO_SEPARATOR;
    const cardNoRegex = new RegExp(VALIDATION_REGEX.CARD_NO);

    const cardLogo = getCardLogo(cardType?.type?.toUpperCase());

    const validateCardForEmi = async (cardNo) => {
        const response = await checkBinEmiEligibility(cardNo.substring(0, 6), bankCode);
        if (response.data?.error) {
            setCardNoError(response.data.error)
        }
    }

    const validateCardNo = () => {
        if (sanitizedCardNo.length < CARD_CONFIG.MIN_CARD_NO_LENGTH) {
            return
        }
        const validate = cardNoValidator(sanitizedCardNo);
        if (!validate) {
            setCardNoError(ERROR_MSSG.CARD_NO.INVALID);
            return;
        }
        if (sanitizedCardNo.length === CARD_CONFIG.MAX_CARD_NO_LENGTH) {
            isEmi && validateCardForEmi(sanitizedCardNo);
            expiryRef.current.focus();
        }
        setCardNoError('')
    }

    const validateCardCategory = (binData) => {
        let cardCategory;
        if (binData.cardCategory === 'CC') {
            cardCategory = 'creditcard'
        }
        if (binData.cardCategory === 'DC') {
            cardCategory = 'debitcard'
        }
        if (!cardCategory) {
            return
        }
        for (const subType of paymentMethods[cardCategory].data) {
            if (subType.code.toUpperCase() === binData.cardType) {
                setCardNoError('');
                setIsCardTypeValid(true)
                return
            }
            setCardNoError(ERROR_MSSG.CARD_NO.NOT_SUPPORTED)
        }
    }

    const binInfo = async () => {
        if (sanitizedCardNo.length === 3) {
            const cardInfo = getCardInfo(sanitizedCardNo)
            cardInfo && setCardType(cardInfo)
        }
        if (sanitizedCardNo.length < 6) {
            setCardBinInfo('')
            if (cardCategory) {
                setCardCategory('')
            }
            if (isCardTypeValid) {
                setIsCardTypeValid(true);
                setCardTypeInvalidError('')
            }
            if (cardNoError) {
                setCardNoError('')
            }
            return
        }

        if (sanitizedCardNo.length === 6) {
            const response = await getBinInfo(sanitizedCardNo);
            if (response.data.error) {
                setCardNoError(response.data.error);
                setIsCardTypeValid(false);
            }
            setCardBinInfo(response.data);
            if (response.data.cardCategory === 'CC') {
                setCardCategory('credit_card')
            }
            if (response.data.cardCategory === 'DC') {
                setCardCategory('debit_card')
            }
            !cardType && setCardType({
                type: response?.data.cardType
            });
            validateCardCategory(response.data)
        }


    }

    const runInputValidation = (value) => {
        // Validate input type
        if (!cardNoRegex.test(sanitizedCardNo) && value !== '') {
            return false
        }

        // Validate card no length
        if (sanitizedCardNo.length > CARD_CONFIG.MAX_CARD_NO_LENGTH) {
            return false
        }

        // Validate card no (luhn algoritham)
        sanitizedCardNo.length >= CARD_CONFIG.MIN_CARD_NO_LENGTH && isCardTypeValid && validateCardNo()

        return true
    }


    const handleOnCardNoChange = async ({ target }) => {
        sanitizedCardNo = sanitize.cardNo(target.value);

        const isInputValidated = runInputValidation(target.value);
        if (!isInputValidated) {
            return
        }

        const splitNo = chunk(sanitizedCardNo, 4)?.join(separator);
        setCardNo(splitNo || '');
        // setCardNoError('')

        // Get Bin info
        binInfo()

        if (sanitizedCardNo.length < 3 && cardType) {
            setCardType(null)
        }

    }

    const handleOnBlur = () => {
        if (!sanitize.cardNo(cardNo)) {
            setCardNoError(ERROR_MSSG.CARD_NO.EMPTY);
            return;
        }
        if (sanitize.cardNo(cardNo)?.length < CARD_CONFIG.MIN_CARD_NO_LENGTH) {
            setCardNoError(ERROR_MSSG.CARD_NO.INVALID);
            return;
        }
    }

    return (
        <NewCardFieldContainer data-testid='card-no'>
            <NewCardInputLabel for="card-no">New Card</NewCardInputLabel>
            <InputContainer className='d-flex'>
                <Input
                    id="card-no"
                    onPaste={e => e.preventDefault()}
                    placeholder="Enter Card Number"
                    data-testid='card-no-input'
                    value={cardNo}
                    onChange={handleOnCardNoChange}
                    onBlur={handleOnBlur}
                    autoComplete="cc-number"
                />
                {!!cardLogo && <img src={cardLogo} alt="card" />}
            </InputContainer>
            {cardNoError && <ErrorText data-testid='card-no-error'>{cardNoError}</ErrorText>}
        </NewCardFieldContainer>
    )
};

export default CardNo;