import styled from "styled-components";
import { P2 } from "../../components/Styled/Text";

export const CustomExpandedContainer = styled.div`
    padding: 0px 45px 0px 20px;
`

export const PaymentForm = styled.div`
    margin-left: 38px;
`

export const MobileNoLabel = styled(P2)`
	color: #2e2e2e;
	padding-bottom: 10px;
    margin-bottom: 0px;
`