import { useContext } from "react";
import { NavigationContext } from "../../../state/Navigation/Navigation";

import {
    Amount,
    AmountBreakupContainer,
    AmountContainer,
    AmountTextContaier,
    AmountText,
    Container,
    Divider,
    CollapsableIconContainer,
    HeaderContainer,
    HeaderPrimaryText,
    MerchantLogo,
    HeaderSecondaryText,
    PrimaryText,
    InvoiceDetails,
    SubContainer,
    Triangle
} from "./styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { backArrow } from "config/images";
import { P3 } from "components/Styled/Text";
import { useFetch } from "hooks/useFetch";

import { InfoContext } from "state/Info/Info";

const LeftSideContainer = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { leftSideCard: { header }, isAmountBreakupOpen, setIsAmountBreakupOpen } = useContext(NavigationContext);
    const { pathname } = useLocation();
    const { redirectOnCancellation } = useFetch();

    const { order, convenienceFee: amountConvenienceFee, paymentMethods } = useContext(InfoContext);

    const subTotal = !!order && parseFloat(order.amount) / 100;
    const convenienceFee = parseFloat(amountConvenienceFee) || 0;
    const gst = (convenienceFee/100)*parseFloat(paymentMethods.convenience_gst) || 0;
    const totalAmount = subTotal + convenienceFee + gst;
    const subRoute = pathname.split('/')[3];

    const handleGoBack = () => {
        if (!subRoute) {
            const confirmGoBack = window.confirm('Are you sure you want to cancel the transaction?');
            if (confirmGoBack) {
                redirectOnCancellation();
            }
            return
        }
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate(`/pay/${orderId}`);
        }

    }

    const AmountBreakup = () => (
        <>
            <AmountBreakupContainer>
                <AmountContainer>
                    <P3>Subtotal</P3>
                    <P3 className="text-dark">{order.currency} {subTotal && parseFloat(subTotal.toFixed(2))?.toLocaleString('en-IN')}</P3>
                </AmountContainer>
                {
                    !!convenienceFee && <AmountContainer>
                        <P3>Convenience Fee</P3>
                        <P3 className="text-dark">{order.currency} {parseFloat(convenienceFee.toFixed(2)).toLocaleString('en-IN')}</P3>
                    </AmountContainer>
                }
                {
                    !!gst && <AmountContainer>
                        <P3>GST</P3>
                        <P3 className="text-dark">{order.currency} {gst.toFixed(2)}</P3>
                    </AmountContainer>
                }
            </AmountBreakupContainer>
            <Divider />
        </>
    )

    return (
        <Container className="col-md-5 col-12">
            <HeaderContainer>
                <img data-testid="back-btn" style={{ cursor: 'pointer' }} src={backArrow} alt='back' onClick={handleGoBack} />
                <HeaderPrimaryText>Back</HeaderPrimaryText>
                {order.merchant?.logo && <MerchantLogo alt={order.merchant.name} className="img-fluid" src={order.merchant.logo}/>}
            </HeaderContainer>
                {order.merchant?.name && <HeaderSecondaryText>{order.merchant.name.length > 14 ? order.merchant.name.substring(0,14) + "..." : order.merchant.name}</HeaderSecondaryText>}
            <SubContainer>
                <PrimaryText>{header}</PrimaryText>
                <AmountTextContaier>
                    <AmountText>Total Payable Amount </AmountText>
                    <Amount>
                        <span>{order.currency} </span>
                        <span>{parseFloat(totalAmount.toFixed(2)).toLocaleString('en-IN')}</span>
                    </Amount>
                    <CollapsableIconContainer onClick={() => setIsAmountBreakupOpen(!isAmountBreakupOpen)}>
                        <Triangle up={isAmountBreakupOpen} />
                    </CollapsableIconContainer>
                </AmountTextContaier>
                {isAmountBreakupOpen && <AmountBreakup />}
            </SubContainer>
            <InvoiceDetails>{`Transaction Id: ${order?.receipt_id}`}</InvoiceDetails>
        </Container>
    )
}

export default LeftSideContainer;