import { cardIcon, emiIcon, netBankingIcon, upiIcon, walletIcon, scanAndPayIcon } from "config/images";

const getWallets = (walletData) => {
    const allWallets = walletData?.map(wallet => wallet?.title?.split(' ')[0])
    return allWallets?.slice(0,-1)?.join(',') + " or " + allWallets?.slice(-1)
}

const getOptionData = (method, methodData) => {
    switch (method) {
        case 'netbanking':
            return {
                name: 'Net Banking',
                subHeader: `Pay using any of ${methodData?.data?.length} supported banks`,
                icon: netBankingIcon,
                route: 'netbanking',
                code: 'netbanking',
            }
        case 'debitcard':
            return {
                name: 'Cards (Credit/Debit)',
                subHeader: 'Pay using any Credit or Debit Card',
                icon: cardIcon,
                route: 'cards',
                code: 'cards',
            }
        case 'creditcard':
            return {
                name: 'Cards (Credit/Debit)',
                subHeader: 'Pay using any Credit or Debit Card',
                icon: cardIcon,
                route: 'cards',
                code: 'cards',
            }
        case 'wallet':
            return {
                name: 'Wallet',
                subHeader: `Pay using ${getWallets(methodData?.data)}`,
                icon: walletIcon,
                route: 'wallet',
                code: 'wallet',
            }
        case 'upi':
            return {
                name: 'UPI',
                subHeader: 'Pay with any UPI app like PhonePe, Paytm, Google Pay and more',
                icon: upiIcon,
                route: 'upi',
                code: 'upi',
            }
        case 'emi':
            return {
                name: 'EMI',
                subHeader: 'Credit Card EMI & Debit Card EMI options available',
                icon: emiIcon,
                route: 'emi',
                code: 'emi',
            }
        case 'scanAndPay':
            return {
                name: 'Scan & Pay',
                subHeader: 'Scan QR code and pay with Phonepe, Paytm, Google Pay and more',
                icon: scanAndPayIcon,
                route: 'scanAndPay',
                code: 'scanAndPay',
            }
        default:
            return null;
    }
}

export const paymentMethodsToDisplay = (methods, isTestOrder) => {
    let methodsToDisplay = [];
    let isCardAvailable = false;

    for (const method in methods) {
        if (method === 'scanAndPay' && isTestOrder) {
            continue;
        }
        if(isCardAvailable && ["debitcard","creditcard"].includes(method)){
            continue;
        }
        const methodData = getOptionData(method, methods[method]);
        if(methodData && methodData?.code=="cards" && methods[method]){
            isCardAvailable=true;
        }
        if (methodData && methods[method]) {
            methodsToDisplay.push({
                ...methodData,
                priority: parseInt(methods[method]?.priority)
            })
        }
    }

    methodsToDisplay = methodsToDisplay.sort((a, b) => a.priority - b.priority)
    return methodsToDisplay;
}