import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import mapPath from "../../helper/mapPath";

export const NavigationContext = createContext("navigation");

const NavigationProvider = ({ children }) => {
    const initialLeftSideCard = {
        header: "",
    }
    const [leftSideCard, setLeftSideCard] = useState(initialLeftSideCard);
    const [isAmountBreakupOpen, setIsAmountBreakupOpen] = useState(false);
    const [rightSideHeader, setRightSideHeader] = useState("");
    const { pathname } = useLocation();

    const getPath = () => {
        const path = pathname.split('/');
        if (path[4]) {
            return path[3] + '/' + path[4]
        }
        return path[3];
    }

    useEffect(() => {
        const updatedProps = mapPath(getPath());
        const updatedLeftSideCard = {
            ...leftSideCard,
            ...updatedProps?.leftSideCard
        }
        setLeftSideCard(updatedLeftSideCard);
        setRightSideHeader(updatedProps?.rightSideHeader)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const values = {
        leftSideCard, 
        setLeftSideCard, 
        rightSideHeader, 
        setRightSideHeader,
        isAmountBreakupOpen,
        setIsAmountBreakupOpen
    }

    return (
        <NavigationContext.Provider value={values}>
            {children}
        </NavigationContext.Provider>
    )
}

export default NavigationProvider