import styled from 'styled-components';
import { P2, P3 } from 'components/Styled/Text';

export const AmountText = styled.span`
    color: ${props => props.theme.colors.primary};
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    align-items: center;
    padding-top: 5%;
`

export const CheckStatus = styled.div`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const OtherText = styled(P2)`
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
`

export const PrimaryText = styled.h4`

    max-width: 60%;
    text-align: center;
`

export const SecondaryText = styled(P3)`
    max-width: 80%;
    text-align: center;
`

export const UpiImage = styled.img`
    margin: 20px 0;
`

export const SubFooter = styled.div`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Timer = styled.span`
    color: ${props => props.theme.colors.danger};
    font-weight: bold;
`