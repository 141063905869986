import { aesEncrypt, aesDecrypt } from "services/crypto";

const getFavicon = () => {
    return document.getElementById('favicon');
}

export const updateGroupUi = (data) => {
    // Update favicon
    const favicon = getFavicon();
    favicon.href = data.favicon;
    // Update font
    document.body.style.fontFamily = data.styles['font-family']
    // Update site title
    document.title = data.name;
    // Update theme color meta tag
    document.querySelector('meta[name="description"]').setAttribute('content', data.colors.primary)
}

export const loader = {
    show: () => {
        document.getElementById("root").style.display = "none";
        document.getElementById("loader").style.display = "block";
    },
    hide: () => {
        document.getElementById("root").style.display = "block";
        document.getElementById("loader").style.display = "none";
    }
}

export const localSt = {
    addOrderId: (orderId) => {
        localStorage.setItem('orderId', aesEncrypt(orderId))
    },
    getOrderId: () => aesDecrypt(localStorage.getItem('orderId'))
}

export const getOrderIdFromUrl = (path) => path.split('/')[2];