import { useContext } from "react";
import styled from "styled-components";
import { NavigationContext } from "state/Navigation/Navigation";
import { CardContext } from "state/Card";

import { settingsIcon } from "config/images";
import { useLocation } from "react-router-dom";

const RightSideHeader = () => {
    const {rightSideHeader} = useContext(NavigationContext);
    const {setShowSavedOptions, savedCards} = useContext(CardContext);
    const { pathname } = useLocation();
    const isCards = pathname.split('/')[3] === 'cards';

    return (
        <Container>
            <HeaderPaymentText>{rightSideHeader}</HeaderPaymentText>
            {isCards && !!savedCards.length && <img style={{cursor: 'pointer'}} src={settingsIcon} alt='Settings' onClick={() => setShowSavedOptions(true)}/>}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 35px 45px 10px 37px; 
`

const HeaderPaymentText = styled.p`
	font-size: 11px;
	color: #8E8E8E;
	text-transform: uppercase;
    margin: 0 5px 0 0;
    white-space: nowrap;
`

export default RightSideHeader;