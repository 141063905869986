import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ErrorImg = styled.img`
    width: 25vw;
    max-width: 20vw;
`

export const Logo = styled.img`
    margin-top: 30px;
    max-width: 180px;
`

export const Header = styled.h2`
    margin: 25px 0 10px;
`