const formId = 'payment-form'

export const createFormTemplate = (formUrl) => {
    const form = document.createElement('form');
    form.setAttribute('id', formId)
    form.setAttribute('type', 'hidden');
    form.setAttribute('method', 'post');
    form.setAttribute('action', formUrl);

    const submit = document.createElement('input');
    submit.setAttribute('type', 'submit');
    submit.setAttribute('type', 'hidden')

    form.appendChild(submit)

    document.getElementsByTagName('body')[0].appendChild(form);
    return form
}

export const createFormInput = (inputName, inputValue) => {

    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', inputName)
    input.setAttribute('value', inputValue);

    const form = document.getElementById(formId)
    form.appendChild(input)
}

export const submitForm = () => {
    document.getElementById("root").style.display = "none";
    document.getElementById("loader").style.display = "block";

    setTimeout(() => {
        document.getElementById(formId).submit();
    }, 100)
}