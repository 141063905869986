import styled from "styled-components"

export const AmountText = styled.h2`
    margin: 0 5px 0 0 ;
    font-size: 14px;
    color: #2e2e2e;
    font-weight: 500;
    
`

export const Amount = styled.p`
    margin: 0 7px 0 0;
`

export const AmountBreakupContainer = styled.div`
    padding: 0 0 10px;
`

export const AmountContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
`

export const AmountTextContaier = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    @media screen and (max-width: 768px) {
        justify-content: center;
    }
   
`

export const Container = styled.div`
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    height: 100%;
    overflow-y: hidden;
    padding: 0 15px;
    @media screen and (max-width: 768px) {
        height: unset;
        text-align: center;
    }
`

export const Divider = styled.div`
  border-top: 1px solid rgb(142, 142, 142);
  width: 36px;
  display: block;
  margin-top: 7px;
`

export const CollapsableIconContainer = styled.i`
    background-color: #4a90e2;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HeaderContainer = styled.div`
    padding: 24px 10px 0 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const HeaderPrimaryText = styled.p`
    margin: 0 auto 0 15px;
`

export const HeaderSecondaryText = styled.p`
    color: #7c7c7c;
    font-weight: 900;
    font-size: 14px;
    margin: 0;
    margin-left: 10px;
`

export const PrimaryText = styled.h2`
    font-weight: 900;
    font-size: 24px;
    color: #2e2e2e;
`

export const InvoiceDetails = styled.p`
    color: #999999;
    font-size: 10px;
    padding: 5px 10px 0 14px;
`

export const MerchantLogo = styled.img`
    max-height: 3.5rem;
    max-width: 7rem;
    object-fit: contain;
    /* width: 7vw; */
`

export const SubContainer = styled.div`
    padding: 25px 0 0 14px;
`

export const Triangle = styled.div`
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    cursor: pointer;
    border-bottom: ${props => !props.up && '4px solid white'};
    border-top: ${props => props.up && '4px solid white'}
`