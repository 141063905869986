import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Aes from 'crypto-js/aes';
import ECB from 'crypto-js/mode-ecb';

const aesKey = process.env.REACT_APP_AES_KEY

export const decryptParams = (data) => {
    return JSON.parse(
        base64Decode(
            aesDecrypt(data)
        )
    );
}

export const base64Decode = (data) => Base64.parse(data).toString(Utf8);

export const base64Encode = (data) => Base64.stringify(Utf8.parse(data));

export const aesDecrypt = (data) => {
    try {
        return Aes.decrypt(data, Utf8.parse(aesKey), {
            mode: ECB
        }).toString(Utf8);
    } catch (error) {
        console.error(`Unable to decrypt: ${data}`)
    }
}

export const aesEncrypt = (data) => {
        return Aes.encrypt(data, Utf8.parse(aesKey), {
            mode: ECB
        }).toString()
}

export const encryptData = (data) => {
    return aesEncrypt(
        base64Encode(data)
    )
}