import { useContext } from "react"
import { CardContext } from "state/Card"
import PaymentOption from "components/Payment/PaymentOption/PaymentOption";
import SelectedPayment from "layout/SelectedPayment/SelectedPayment";
import { SelectedPaymentSubContainer } from "layout/SelectedPayment/SelectedPayment";
import { getCardLogo, getSavedCardTitle } from "../helper";
import { cardIcon, plusIcon } from "config/images";
import { Input1 } from "components/Styled/Misc";
import { VALIDATION_REGEX, CARD_CONFIG} from "config/static";
import useProceedToPay from "hooks/useProceedToPay";
import { encryptData } from "services/crypto";

import { ProceedBtn } from "routes/PaymentOptions/styled";
import { TEXT } from "config/static";

const SavedCards = ({ showNewCard, setShowNewCard }) => {
    const { savedCards, savedCardToken, setSavedCardToken, setCvv, cvv } = useContext(CardContext);
    const {proceedToPay} = useProceedToPay();
    const isProceedBtnDisabled = cvv.length < CARD_CONFIG.MIN_CVV_LENGTH;

    const handleOnChangeCvv = ({ target: { value } }) => {
        const cvvRegex = new RegExp(VALIDATION_REGEX.CVV);
        if (!cvvRegex.test(value)) {
            if (value !== '') {
                return
            }
        }

        if (value.length > CARD_CONFIG.MAX_CVV_LENGTH) {
            return
        }
        setCvv(value);
    }

    const handleProceedToPay = () => {
        const cardInfo = {
            token: savedCardToken,
            cvv: cvv
        }

        const stringifiedCardInfo = JSON.stringify(cardInfo);
        const encryptedCardInfo = encryptData(stringifiedCardInfo);

        proceedToPay({ payMethod: 'CC_DC_SC', data: encryptedCardInfo})
    }

    return (
        <>
            {
                savedCards?.map((card, i) => (
                    savedCardToken !== card.token ? <PaymentOption
                        key={i}
                        code={card.token}
                        name={getSavedCardTitle(card)}
                        icon={{left: getCardLogo(card.brand) || cardIcon, altLeft: cardIcon}}
                        onClick={() => {
                            setSavedCardToken(card.token);
                            setCvv('');
                            setShowNewCard(false)
                        }}
                    /> : <SelectedPayment
                        key={i}
                        code={card.token}
                        name={getSavedCardTitle(card)}
                        icon={{left: getCardLogo(card.brand) || cardIcon, altLeft: cardIcon}}
                        onClose={() => setSavedCardToken("")}
                    >
                        <SelectedPaymentSubContainer>
                            <Input1
                                id="cvv"
                                type="password"
                                autoComplete="off"
                                placeholder="Enter CVV"
                                data-testid="card-cvv-input"
                                value={cvv}
                                onChange={handleOnChangeCvv}
                            />
                            <ProceedBtn isDisabled={isProceedBtnDisabled} onClick={handleProceedToPay}>{TEXT.PAY_BTN}</ProceedBtn>
                        </SelectedPaymentSubContainer>

                    </SelectedPayment>

                )
                )
            }
            {
                !showNewCard && <PaymentOption
                    name="New Card"
                    icon={{left: plusIcon}}
                    onClick={() => {
                        setShowNewCard(true);
                        setSavedCardToken('');
                    }}
                />
            }
        </>
    )

}

export default SavedCards