import { useContext } from "react";

import { NewCardInputLabel, NewCardFieldContainer } from "../styled";
import { Input1 } from "../../Styled/Misc";

import { CardContext } from "state/Card";

const Name = () => {
    const {name, setName, cardNameRef} = useContext(CardContext);

    const handleOnChangeName = ({ target }) => {
        if (target.value.length > 50) {
            return
        }
        setName(target.value)
    }

    return (
        <NewCardFieldContainer data-testid="card-name">
            <NewCardInputLabel for="card-name-input"> Name on Card</NewCardInputLabel>
            <Input1 
                placeholder="Enter name as on card" 
                data-testid="card-name-input"
                id="card-name-input"
                autoComplete="cc-name"
                value={name} 
                onChange={handleOnChangeName} 
                ref={cardNameRef}
                className='mb-4'
            />
        </NewCardFieldContainer>
    )
}

export default Name;