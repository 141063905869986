import { useContext, useEffect } from "react";
import {
    NewCardContainer,
    NewCardFieldsContainer,
} from "./styled";
import { PrimaryPayBtn } from "components/Styled/Buttons";
import Expiry from "components/Card/Expiry/Expiry";
import CardNo from "components/Card/CardNo/CardNo";
import Cvv from "components/Card/Cvv/Cvv";
import Name from "components/Card/Name/Name";
import { TEXT } from "config/static";
import useProceedToPay from "hooks/useProceedToPay";

import { CardContext } from "state/Card";
import { InputContext } from "state/Input/Input";
import { InfoContext } from "state/Info/Info";
import { EmiContext } from "state/Emi/Emi";

import { encryptData } from "services/crypto";
import { sanitizeCardNo } from "./helper";

import ConsentBox from "components/ConsentBox/ConsentBox";

const Card = ({ isEmi }) => {
    const {
        cardNo,
        expiry,
        cvv,
        name,
        isCardTypeValid,
        setCardNo,
        setExpiry,
        setCvv,
        setName,
        setCardNoError,
        setExpiryError,
        setCvvError,
        cardNoError,
        expiryError,
        cvvError,
        cardBinInfo
    } = useContext(CardContext);
    const { selectedEmiCode } = useContext(EmiContext);
    const { consentCheckbox } = useContext(InputContext);
    const { order } = useContext(InfoContext);

    const { proceedToPay } = useProceedToPay();

    useEffect(() => {
        const cleanInput = () => {
            setCardNo('');
            setExpiry('');
            setCvv('');
            setName('');
            setCardNoError('');
            setExpiryError('');
            setCvvError('');
        }

        return () => {
            cleanInput()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isPayBtnDisabled = () => {
        if (!cardNo || !expiry || !cvv) {
            return true
        }
        if (!!cardNoError || !!expiryError || !!cvvError) {
            return true
        }
        if (!isCardTypeValid) {
            return true
        }
        return false
    }

    const handleProceedToPay = () => {
        // Encrypt card info
        const cardInfo = {
            cardNo: sanitizeCardNo(cardNo),
            cvv,
            name,
            expiryMonth: expiry.split('/')[0],
            expiryYear: expiry.split('/')[1],
        }

        if (isEmi) {
            cardInfo.bankCode = selectedEmiCode;
        }

        const stringifiedCardInfo = JSON.stringify(cardInfo);
        const encryptedCardInfo = encryptData(stringifiedCardInfo);
        const payementMethod = () => {
            if (isEmi) {
                return 'EMI'
            }
            if (!order.is_test) {
                return 'cc_dc'
            }
            if (cardBinInfo?.cardCategory) {
                return cardBinInfo.cardCategory
            }
            return 'CC'
        }

        console.log(order)

        proceedToPay({ payMethod: payementMethod(), data: encryptedCardInfo, methodType: cardBinInfo?.cardType, isSaved: consentCheckbox })
    }

    return (
        <NewCardContainer isSelected={true}>
            <NewCardFieldsContainer>
                <CardNo />
                <div className="row">
                    <Expiry />
                    <Cvv />
                </div>
                <Name />
                { (!order.is_test && order.is_save_card) && <ConsentBox />}
                <PrimaryPayBtn disabled={isPayBtnDisabled()} onClick={handleProceedToPay} isDisabled={isPayBtnDisabled()}>{TEXT.PAY_BTN}</PrimaryPayBtn>
            </NewCardFieldsContainer>
        </NewCardContainer>
    )
}

export default Card