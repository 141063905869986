export const VALIDATION_REGEX = {
    MOBILE_NUMBER: /^[6-9][0-9]*$/, 
    EMAIL: '[a-z0-9]+@[a-z]+\.[a-z]{2,3}', // eslint-disable-line
    CARD_NO: /[0-9]$/,
    CVV: /[0-9]$/,
    EXPIRY: /[0-9]$/,
    UPI_EXCEPT_ANY: /^[A-Za-z0-9.\-_]*$/,
    UPI_ANY: /^[A-Za-z0-9._\-@]*$/
}

export const ERROR_MSSG = {
    EMAIL_INVALID: 'Email Address is invalid',
    MOBILE_INVALID: 'Mobile Number is invalid',
    MOBILE_REQUIRED: 'Mobile Number is required',
    //Card No
    CARD_NO: {
        INVALID: 'Card number is invalid! Check again',
        EMPTY: 'Card number is required',
        NOT_SUPPORTED: "This card is not supported"
    },
    // Card Expiry
    CARD_EXPIRY_PAST: 'Expiry cannot be in past',
    CARD_EXPIRY_EMPTY: 'Expiry is required',
    // Card CVV
    INVALID_CVV: 'Invalid CVV',
    CARD_CVV_EMPTY: 'Cvv is required',
}

export const CARD_CONFIG = {
    MIN_CARD_NO_LENGTH: 14,
    MAX_CARD_NO_LENGTH: 16,
    MIN_CVV_LENGTH: 3,
    MAX_CVV_LENGTH: 4,
    EXPIRY_SEPARATOR: '/',
    MAX_EXPIRY_YEAR: 99,
    CARD_NO_SEPARATOR: '-',
}

export const MAX_MIN_CONFIG = {
    MOBILE_MAX_DIGITS: 10,
    MOBILE_MIN_DIGITS: 10,
    MAX_UPI_ID_LENGTH: 120,
    MIN_UPI_ID_LENGTH: 1
}

export const TEXT = {
    SELECTED_WALLET: 'Keep mobile handy to complete payment with OTP',
    PAY_BTN: 'PROCEED',
}

export const CURRENCY = {
    RUPEE: 'INR'
}

export const CARD_TYPE = {
    CC: 'Credit Card',
    DC: 'Debit Card'
}

export const REDIRECT_CALLBACK_ACTIONS = {
    UPI_TXN: 'E000',
    GO_BACK: 'E001',
    REDIRECT_TRUE: 'E002'
}