import {
   InfoProvider,
   NavigationProvider,
   InputProvider,
   EmiProvider,
   CardProvider,
   BasicProvider,
} from "state/index.js";

const StateProviders = ({ children }) => (
   <NavigationProvider>
      <BasicProvider>
         <InfoProvider>
            <CardProvider>
               <InputProvider>
                  <EmiProvider>{children}</EmiProvider>
               </InputProvider>
            </CardProvider>
         </InfoProvider>
      </BasicProvider>
   </NavigationProvider>
);

export default StateProviders;
