import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
`

export const BackBtn = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50px;
  left: 50px;
  &:hover {
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 30px;
`

export const Header = styled.h3`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

export const ModalStyled = styled.div`
  position: relative;
  background: white;
  min-width: 30rem;
  z-index: 2;
  padding: 30px;
  border-radius: 10px;
`;

