import styled from 'styled-components';
import { P3 } from 'components/Styled/Text';

export const ModalContainer = styled.div`
    /* display: flex; */
    background-color: rgba(0,0,0,0.75);
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
`

export const Modal = styled.div`
    background-color: white;
    max-width: 455px;
    position: relative;
    top: 30%;
    margin: 0 auto;
    border-radius: 8px;
    padding: 22px 0;
`

export const HeadingContainer = styled.div`
    padding: 0 20px 15px 20px;
`

export const Heading = styled.h3`
    color: #2e2e2e;
    font-size: 18px;
    line-height: 22px;
`

export const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const SubHeading = styled(P3)`
    padding: 12px 0
`