import styled from "styled-components";
import { P1 } from "../Styled/Text";

export const NewCardInputLabel = styled.label`
    font-weight: 500;
    color: #2e2e2e;
    margin-bottom: 4px;
`

export const NewCardFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`

export const NewCardContainer = styled.div`
    background-color: ${props => props.isSelected ? '#f2f2f2' : null};
    border-left: ${props => props.isSelected ? `${props.theme.colors.primary} 4px solid` : null};
`

export const NewCardFieldsContainer = styled.div`
    width: 70%;
    margin: 0 auto 0;
    padding: 20px 0;
`