import { useContext } from 'react';
import { getUri } from 'api/services.js';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { loader } from 'helper/misc';
import toast from 'react-hot-toast';
import { createFormTemplate, createFormInput, submitForm } from 'services/paymentForm';
import { BasicContext } from 'state/Basic/Basic';
import config from 'api/config';
import { REDIRECT_CALLBACK_ACTIONS } from 'config/static';

export const useFetch = (showLoader) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const orderId = pathname.split('/')[2];
    const { apiUrl } = useContext(BasicContext);
    const { version } = config;

    const apiGet = async ({ uri, headers }) => {
        const response = await fetch(getUri(uri, apiUrl), {
            headers: {
                "Content-Type": "application/json",
                ...headers
            }
        })
        return response.json();
    }

    const apiPost = async ({ uri, body, headers }) => {
        try {
            showLoader && loader.show();
            const response = await fetch(getUri(uri, apiUrl), {
                method: 'POST',
                body: JSON.stringify({
                    id: orderId,
                    ...body
                }),
                headers: {
                    "Content-Type": "application/json",
                    ...headers
                }
            })
            const jsonResponse = await response.json();

            if (jsonResponse.status) {
                const toastId = Date.now()

                if (jsonResponse.msg) {
                    toast.success(<span onClick={() => toast.dismiss(toastId)}>{jsonResponse.msg}</span>, {
                        duration: Infinity,
                        id: toastId
                    })
                }
                return jsonResponse;
            }

            if (jsonResponse.exception) {
                navigate('/error', {
                    state: {
                        errorMsg: jsonResponse.exception.message,
                        logId: jsonResponse.exception.log_id,
                        redirect: jsonResponse.redirect
                    }
                })
                return
            }

            if (jsonResponse.error) {
                const toastId = Date.now()
                toast.error(<span onClick={() => toast.dismiss(toastId)}>{jsonResponse.error.message}</span>, {
                    duration: Infinity,
                    id: toastId
                })
            }

        } catch (error) {
            console.log({ error })
        }
        finally {
            showLoader && loader.hide();
        }
    }

    const api = {
        get: apiGet,
        post: apiPost
    }

    const getGroupInfo = async () => {
        const uri = '/group/info';
        return await api.get({ uri });
    }

    const getPaymentMethods = async () => {
        const uri = '/order/payment/methods';
        return await api.post({ uri });
    }

    const processOrder = async ({ pg, data, save }) => {
        const uri = '/order/process';
        return await api.post({ uri, body: { pg, data, save } });
    }

    const getOrderInfo = async (lastOrderId) => {
        const body = {};
        if (lastOrderId) {
            body.id = lastOrderId
        }
        const uri = '/order/info';
        return await api.post({ uri, body });
    }

    const getBankHealth = async (bankCode) => {
        const uri = '/health/bankcode';
        return await api.post({ uri, body: { bank_code: bankCode } });
    }

    const getBinInfo = async (bin) => {
        const uri = '/health/bin';
        return await api.post({ uri, body: { bin_code: bin } });
    }

    const getSavedCards = async() => {
        const uri = '/order/card/list';
        return await api.post({uri})
    }

    const deleteSavedCard = async(token) => {
        const uri = '/order/card/delete';
        return await api.post({uri, body: {token}})
    }

    const verifyUpiId = async (vpa) => {
        const uri = '/order/payment/validate-vpa';
        return await api.post({ uri, body: { vpa } });
    }

    const getEmiOptions = async () => {
        const uri = '/order/payment/emi-amount';
        return await api.post({ uri });
    }

    const checkBinEmiEligibility = async (bin, bankName) => {
        const uri = '/order/payment/emi-bin';
        return await api.post({
            uri, body: {
                bin_code: bin,
                bank_name: bankName
            }
        });
    }

    const generateQr = async () => {
        const uri = '/order/payment/generate-qr';
        return await api.post({ uri, body: {} });
    }

    const getUpiTxnStatus = async (isQr) => {
        const uri = '/order/payment/upi-txn-status';
        return await api.post({
            uri, body: {
                is_qr: isQr
            }
        })
    }

    const redirectToCallBack = async (action, callbackStatus) => {
        try {
            const url = `${apiUrl}/callback/redirect`;

            createFormTemplate(url);
            createFormInput('id', orderId);
            createFormInput('action', action);
            createFormInput('status', callbackStatus);
            submitForm();
        } finally {
            showLoader && loader.hide()
        }
    }

    const redirectToTestCallBack = async (paymentStatus, method, methodType) => {
        try {
            const url = `${apiUrl}/callback/test`;

            createFormTemplate(url);
            createFormInput('id', orderId);
            createFormInput('status', paymentStatus);
            createFormInput('action', 'E000');
            createFormInput('method', method);
            createFormInput('method_type', methodType);
            submitForm();
        } finally {
            showLoader && loader.hide()
        }
    }

    const redirectOnCancellation = async (lastOrderId) => {
        const url = `${apiUrl}/api/${version}/order/cancellation`

        createFormTemplate(url);
        createFormInput('id', lastOrderId || orderId);
        createFormInput('action', REDIRECT_CALLBACK_ACTIONS.GO_BACK);
        submitForm();
    }

    const redirectOnException = async (exceptionCode, lastOrderId) => {
        const url = `${apiUrl}/api/v0/order/redirect`;

        createFormTemplate(url);
        createFormInput('id', lastOrderId || orderId);
        createFormInput('action', exceptionCode);
        submitForm();
    }

    return {
        getGroupInfo,
        getPaymentMethods,
        processOrder,
        getOrderInfo,
        getBankHealth,
        getBinInfo,
        getSavedCards,
        deleteSavedCard,
        verifyUpiId,
        getEmiOptions,
        checkBinEmiEligibility,
        generateQr,
        getUpiTxnStatus,
        redirectToCallBack,
        redirectToTestCallBack,
        redirectOnCancellation,
        redirectOnException
    }
}