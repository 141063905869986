import { useState, useEffect, useContext } from "react";
import PaymentOption from "components/Payment/PaymentOption/PaymentOption";
import SelectedPayment, { SelectedPaymentSubContainer } from "layout/SelectedPayment/SelectedPayment";
import { P4 } from "components/Styled/Text";
import ProceedBtn from 'components/Misc/ProceedBtn.js'
import { bankAltIcon } from "config/images";
import { InfoContext } from "state/Info/Info.js";
import useProceedToPay from "hooks/useProceedToPay.js";
import { useFetch } from "hooks/useFetch.js";
import withPaymentCard from "hoc/withPaymentCard.js";
import Search from "components/Misc/Search.js";
import { calculateConvenienceFeeAmount } from "helper/convenienceFee";

const NetBanking = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [bankList, setBankList] = useState(null);
    const [filteredBankList, setFilteredBankList] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    const useBankList = filteredBankList || bankList;
    const { proceedToPay } = useProceedToPay();
    const { getBankHealth } = useFetch();

    const { paymentMethods, order, setConvenienceFee, convenienceFeeRuleEngine } = useContext(InfoContext);

    useEffect(() => {
        if (!!selectedBank) {
            updateNetbankingConvenienceFee()
        }
    }, [selectedBank])
    
    useEffect(() => {
        if (!paymentMethods) {
            return
        }
        const netBankingMethods = paymentMethods?.netbanking?.data;
        setBankList(netBankingMethods);
    }, [paymentMethods])

    useEffect(() => {
        if (bankList?.length > 0) {
            const updatedBankList = bankList.filter(bank => (
                bank.title.toLowerCase().includes(search.toLowerCase())
            ));
            setFilteredBankList(updatedBankList)
        }
    }, [search]) // eslint-disable-line

    const updateNetbankingConvenienceFee = () => {
        const facts = {
            method: 'netbanking',
            bank: selectedBank.toLowerCase() ,
            amount: order.amount/100
        }
        convenienceFeeRuleEngine?.run(facts).then(({events, almanac}) => {
            if (!almanac.events.success.length) {
                setConvenienceFee(0);
                return
            }
            events.map(event => {
                const fee = calculateConvenienceFeeAmount(event.type, event.params?.value, order.amount);
                if (!fee) {
                    return 
                }
                setConvenienceFee(fee);
            })
        }).catch(e => console.log('error occured'))
    }

    const markBankAsDown = (bankCode) => {
        let bankList = paymentMethods.netbanking;
        const downBankIndex = bankList.findIndex(bank => bank.code === bankCode);
        bankList[downBankIndex].health = false;
        setSelectedBank('');
    }

    const handleProceedToPay = async () => {
        if (!order.is_test) {
            // Check bank health
            setIsLoading(true);
            const { data: { healthy } } = await getBankHealth(selectedBank);
            setIsLoading(false);
            if (!healthy) {
                markBankAsDown(selectedBank)
                // Display bank down
                return
            }
        }

        proceedToPay({ payMethod: 'NB', data: selectedBank, methodType: selectedBank });
    }

    return (
        <div>
            <Search search={search} setSearch={setSearch} />
            {
                useBankList?.map(bank => {

                    if (selectedBank !== bank.code) {
                        return <PaymentOption
                            key={bank.code}
                            code={bank.code}
                            name={bank.title}
                            icon={{left: bank.image, altLeft: bankAltIcon}}
                            onClick={() => setSelectedBank(bank.code)}
                            isDown={!bank.health}
                        />
                    }
                    return <SelectedPayment
                        key={bank.code}
                        code={bank.code}
                        name={bank.title}
                        icon={{left: bank.image, altLeft: bankAltIcon}}
                        onClose={() => setSelectedBank("")}
                        isDown={!bank.health}
                    >
                        <SelectedPaymentSubContainer>
                            <P4 className="me-4">{`You will be redirected to ${bank.title}'s page. Login with netbanking ID and password to complete transaction.`}</P4>
                            <ProceedBtn onClick={handleProceedToPay} text='PROCEED' isLoading={isLoading} />
                        </SelectedPaymentSubContainer>
                    </SelectedPayment>
                })
            }
        </div>
    )
}

export default withPaymentCard(NetBanking);