import { useContext } from "react";
import { useFetch } from "hooks/useFetch";
import { useNavigate, useLocation } from "react-router-dom";

import {
  BackBtn,
  ButtonsContainer,
  Container,
  Footer,
  Header,
  ModalStyled,
} from "./styled";
import { backArrow } from 'config/images.js'
import { InfoContext } from "state/Info/Info";

const TestSimulator = () => {
  const { redirectToTestCallBack } = useFetch();
  const navigate = useNavigate();
  const { group } = useContext(InfoContext);
  const {state} = useLocation()

  const handleOnClick = (status) => {
    redirectToTestCallBack(status, state.payMethod, state.methodType)
  }

  return (
    <Container data-testid="payment-simulator">
      <BackBtn data-testid="back-btn" onClick={() => navigate(-1)}>
        <img src={backArrow} alt="back" className='me-2' />
        <h4 className="mb-0">Go Back</h4>
      </BackBtn>
      <ModalStyled>
        <Header>Choose a payment response</Header>
        <ButtonsContainer>
          <button data-testid="success-btn" className="btn btn-md btn-success w-50 m-1" onClick={() => handleOnClick('success')}>Success</button>
          <button data-testid="failure-btn" className="btn btn-danger w-50 m-1" onClick={() => handleOnClick('failure')}>Failure</button>
        </ButtonsContainer>
      </ModalStyled>
      <Footer>
        <img  src={group.logo} alt="logo" style={{maxWidth: 180}} />
      </Footer>
    </Container>
  );
};

export default TestSimulator;