import styled from "styled-components";

export const EmiOptionsContainer = styled.div`
    padding: 20px 45px 0 41px;
`

export const EmiPlanContainer = styled.div`
    display: flex;
    align-items: flex-start;
`

export const RadioBtn = styled.input`
    margin-right: 15px;
    margin-top: 5px;
`

export const RadioBtnLabel = styled.div`
    cursor: pointer;
`