import styled from "styled-components";

export const BrandLogo = styled.img`
    max-width: 120px;
`

export const Container = styled.div`
    height: 0vh;
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 25px;
    @media screen and (max-width: 768px)  {
        display: unset;
        justify-content: space-between;
        width: unset;
        height: unset;
    }
`

export const RightSideContainer = styled.div`
    @media screen and (max-width: 768px)  {
        display: flex;
        margin-bottom: 15px;
        justify-content: center;
        align-items: center;
        order: 0;
    }
`

export const Image = styled.img`
    margin: 0 5px;
`

export const PrimaryLink = styled.a`
    cursor: pointer;
    color: #8e8e8e;
    font-size: 11px;
`

export const LeftSideContainer = styled.div`
    @media screen and (max-width: 768px)  {
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        order: 1;
    }
`