import { useState, useEffect } from "react";

const useCountdown = (expiryInSec) => {
    const initialMinutes = Math.floor(expiryInSec/60);
    const initialSeconds = expiryInSec % 60;
    const [ minutes, setMinutes ] = useState(initialMinutes);
    const [seconds, setSeconds ] =  useState(initialSeconds);

    useEffect(()=>{
        let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval)
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                } 
            }, 1000)
        
            return ()=> {
                clearInterval(myInterval);
              };
        }, [minutes, seconds]);

    return [minutes, seconds]
}

export default useCountdown