import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InfoContext } from 'state/Info/Info';
import { errorImage500 } from 'config/images';
import { localSt } from 'helper/misc';
import { useFetch } from 'hooks/useFetch';
import { backArrow } from 'config/images';
import { BackBtn } from 'components/Modal/TestSim/styled.js';
import { REDIRECT_CALLBACK_ACTIONS } from 'config/static';

import {
    Container,
    ErrorImg,
    Logo,
    Header
} from './styled'

const Error500 = ({ errorMsg, hideBackBtn }) => {
    const { group } = useContext(InfoContext);
    const { state } = useLocation();
    const { redirectOnCancellation, redirectOnException } = useFetch();
    const errorMessage = state?.errorMsg || errorMsg;
    const lastOrderId = localSt.getOrderId();



    useEffect(() => {
        const redirectIfReq = () => {
            if (state?.redirect) {
                setTimeout(() => {
                    redirectOnException(REDIRECT_CALLBACK_ACTIONS.REDIRECT_TRUE, lastOrderId)
                }, 1000)
            }
        }

        redirectIfReq()
    }, []) // eslint-disable-line

    return (
        <Container className='mt-5 container'>
            {!hideBackBtn && <BackBtn onClick={() => redirectOnCancellation(lastOrderId, REDIRECT_CALLBACK_ACTIONS.GO_BACK)}>
                <img src={backArrow} alt="back" className='me-2' />
                <h4 className="mb-0">Go Back</h4>
            </BackBtn>}
            <ErrorImg className='img-fluid' src={errorImage500} />
            <Header >{errorMessage}</Header>
            {state?.logId && <small>Log Line: <span>{state?.logId}</span></small>}
            <hr />
            <Logo src={group.logo} />
        </Container>
    )
}



export default Error500