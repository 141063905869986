import { useContext } from "react";

import SelectedPayment from "layout/SelectedPayment/SelectedPayment";
import { CustomExpandedContainer, PaymentForm } from "components/Wallets/styled.js";
import MobileNo from "components/Misc/MobileNo";
import { P4 } from "components/Styled/Text";
// import ConsentBox from "components/ConsentBox/ConsentBox";
import { PrimaryPayBtn } from "components/Styled/Buttons";

import { InputContext } from "state/Input/Input";

import { TEXT } from "config/static";

const CustomSelectedWallet = ({wallet, state}) => {
    const {mobileNo, mobileNoError} = useContext(InputContext);

    return (
        <SelectedPayment key={wallet.code} code={wallet.code} name={wallet.title} icon={{left: wallet.image}} onClose={() => state.setSelectedWallet("")}>
            <div data-testid={`selected-${wallet.code}`}>
                <br />
                <CustomExpandedContainer>
                    <PaymentForm>
                        <MobileNo/>
                        <P4 className="mt-2">Phone Number is restricted to India only</P4>
                        {/* <ConsentBox/> */}
                        <PrimaryPayBtn isDisabled={!(mobileNo && !mobileNoError)}>{TEXT.PAY_BTN}</PrimaryPayBtn>
                    </PaymentForm>
                </CustomExpandedContainer>
            </div>
        </SelectedPayment>
    )
}

export default CustomSelectedWallet;