import { useState, useContext } from "react";

import PaymentOption from "components/Payment/PaymentOption/PaymentOption";
import SelectedWallet from "components/Wallet/SelectedWallet";
import CustomSelectedWallet from "components/Wallet/CustomSelectedWallet";
import { walletIcon } from "config/images";

import { InfoContext } from "state/Info/Info";
import withPaymentCard from "hoc/withPaymentCard";

const customWallets = ['CITRUS'];

const Wallets = () => {
    const [selectedWallet, setSelectedWallet] = useState("");
    const [savePaymentOption, setSavePaymentOption] = useState(true);

    const { paymentMethods } = useContext(InfoContext);

    const state = {
        setSelectedWallet,
        savePaymentOption,
        setSavePaymentOption
    }

    return paymentMethods.wallet ? paymentMethods?.wallet?.data.map(wallet => {
        if (wallet.code !== selectedWallet) {
            return (
                <PaymentOption 
                    key={wallet.code} 
                    code={wallet.code} 
                    name={wallet.title} 
                    icon={{left: wallet.image || walletIcon, altLeft: walletIcon}} 
                    onClick={() => setSelectedWallet(wallet.code)} 
                />
            )
        }
        if (wallet.code === selectedWallet && customWallets.includes(wallet.code)) {
            return (
                <CustomSelectedWallet key={wallet.code} wallet={wallet} state={state} />
            )
        }
        return (
            <SelectedWallet key={wallet.code} wallet={wallet} setSelectedWallet={setSelectedWallet} altIcon={walletIcon}/>
        )
    }) : null
}

export default withPaymentCard(Wallets)