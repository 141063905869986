import { useEffect, useState, useContext } from "react";
import { useFetch } from "hooks/useFetch";

import NewCard from "components/Card/Card";
import { CardContext } from "state/Card";
import { InfoContext } from "state/Info/Info";
import PaymentCard from "layout/PaymentCard/PaymentCard";
import SavedOptions from "components/Modal/SavedOptions/SavedOptions";

import SavedCards from "components/Card/SavedCards/SavedCards";

const CardsRoute = () => {
    const { getSavedCards } = useFetch(true);
    const { savedCards, setSavedCards, showSavedOptions } = useContext(CardContext);
    const { order } = useContext(InfoContext);
    const [showNewCard, setShowNewCard] = useState(false);

    useEffect(() => {
        const getCards = async () => {
            const response = await getSavedCards();
            setSavedCards(response.data)
        }
        !savedCards.length && !order.is_test && order.is_save_card && getCards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <PaymentCard>
                {
                    savedCards.length > 0 && <SavedCards showNewCard={showNewCard} setShowNewCard={setShowNewCard} />
                }
                {(savedCards.length === 0 || showNewCard) && <NewCard />}
            </PaymentCard>
            {showSavedOptions && <SavedOptions/>}
        </>
    )
};

export default CardsRoute;