import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import withPaymentCard from 'hoc/withPaymentCard.js';

import PaymentOption from "components/Payment/PaymentOption/PaymentOption";
import { CategoryHeading } from 'components/Styled/Text';
import Search from 'components/Misc/Search';
import { EmiContext } from 'state/Emi/Emi';

import { bankAltIcon } from 'config/images';

const EmiType = () => {
    const { orderId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const type = pathname.split('/').slice(-1)[0];
    const { options } = useContext(EmiContext);

    const [search, setSearch] = useState('');
    const [emiOptions, setEmiOptions] = useState(options[type])

    useEffect(() => {
        if (!options) {
            navigate(`/pay/${orderId}/emi`);
            return
        }
        if (!options[type].length) {
            return
        }
        const filteredList = options[type].filter(option => (
            option.title.toString().toLowerCase().includes(search.toLowerCase())
        ));
        setEmiOptions(filteredList)
    }, [search]) // eslint-disable-line

    return (
        <div>
            <Search search={search} setSearch={setSearch} />
            <CategoryHeading>All Banks</CategoryHeading>
            {
                emiOptions?.map((option, i) => {
                    return <PaymentOption
                        key={i}
                        code={option.bank_code}
                        name={option.title}
                        icon={{left: option.image, altLeft: bankAltIcon}}
                        onClick={() => navigate(option.bank_code.toString())}
                    />
                }
                )
            }
        </div>
    )
}


export default withPaymentCard(EmiType);