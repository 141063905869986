import styled from "styled-components";
import { PrimaryPayBtn } from "../../components/Styled/Buttons";
import './style.css'

const ProceedBtn = ({ text, onClick, isLoading, isDisabled = false }) => {
    const Loader = () => (
        <div className="lds-ring d-flex justify-content-center align-items-center" >
            <div></div>
        </div>
    )

    return (
        <StyledProceedBtn disabled={isLoading || isDisabled} isDisabled={isLoading || isDisabled} onClick={!isLoading && !isDisabled ? onClick : undefined}>
            {isLoading ? <Loader/> : text}
        </StyledProceedBtn>
    )
}

const StyledProceedBtn = styled(PrimaryPayBtn)`
    pointer-events: ${props => props.isDisabled & 'disabled'};
    /* margin-left: 40px; */
    padding: 1px 8px;
`

export default ProceedBtn