import PaymentCard from 'layout/PaymentCard/PaymentCard.js';

const withPaymentCard = (OriginalComponent) => {
    const UpdatedComponent = () => (
        <PaymentCard>
            <OriginalComponent/>
        </PaymentCard>
    )
    return UpdatedComponent
}

export default withPaymentCard;