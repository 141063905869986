import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PaymentOption from "../Payment/PaymentOption/PaymentOption";
import { InfoContext } from "state/Info/Info";
import { cardIcon, creditCard } from "config/images";

import withPaymentCard from "hoc/withPaymentCard";
import { useFetch } from "hooks/useFetch";

import { EmiContext } from "state/Emi/Emi";


const Emi = () => {
    const navigate = useNavigate();
    const { options, setEmiOptions } = useContext(EmiContext);
    const { paymentMethods } = useContext(InfoContext);
    const { getEmiOptions } = useFetch(true);

    useEffect(() => {
        const getEmi = async () => {
            const { data } = await getEmiOptions();
            setEmiOptions(data)
        }

        if (!paymentMethods) {
            return
        }
        if (!options) {
            getEmi()
        }
    }, [options, paymentMethods]) // eslint-disable-line 

   

    const handleOnClick = (emiType) => {
        navigate(emiType)
    }

    return (
        <div>
            {
                options?.cc?.length > 0 && <PaymentOption
                    name="Credit Card EMI"
                    code="cc"
                    icon={{left: creditCard}}
                    onClick={() => handleOnClick('cc')}
                />
            }
            {
                options?.dc?.length > 0 && <PaymentOption
                    name="Debit Card EMI"
                    code="dc"
                    icon={{left: cardIcon}}
                    onClick={() => handleOnClick('dc')}
                />
            }
        </div>
    )
};

export default withPaymentCard(Emi);