import { createContext, useState } from "react";

export const EmiContext = createContext("");

const EmiProvider = ({children}) => {
    const [emiOptions, setEmiOptions] = useState('');
    const [selectedEmiCode, setSelectedEmiCode] = useState('');

    const values = {
        options: emiOptions,
        setEmiOptions, 
        selectedEmiCode,
        setSelectedEmiCode
    }

    return (
        <EmiContext.Provider value={values}>
            {children}
        </EmiContext.Provider>
    )

}

export default EmiProvider