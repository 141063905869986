import { useContext, useEffect } from "react";
import styled from "styled-components";
import { checkBoxChecked, checkboxUnchecked } from '../../config/images.js';
import { P3 } from "../Styled/Text.js";

import { InputContext } from "state/Input/Input.js";

const ConsentBox = () => {
    const {consentCheckbox, setConsentCheckbox} = useContext(InputContext);

    useEffect(() => {
        return () => {
            setConsentCheckbox(true)
        }
    }, []) // eslint-disable-line

    return (
        <ConsentBoxContainer data-testid="consent-checkbox" onClick={() => setConsentCheckbox(!consentCheckbox)}>
                <img className="pe-2" src={consentCheckbox ? checkBoxChecked : checkboxUnchecked} alt='consent checkbox' />
                <P3 className="mb-0">Save this option securely for faster payment</P3>
        </ConsentBoxContainer>
    )
}

const ConsentBoxContainer = styled.div`
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    cursor: pointer;
`

export default ConsentBox;