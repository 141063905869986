import styled from 'styled-components';

import { searchIcon } from 'config/images';

const Search = ({search, setSearch}) => (
    <SearchInputContainer data-testid='search-input-container'>
        <SearchInput 
            data-testid="search-input"
            placeholder="Search for your bank" 
            autoFocus={true} 
            value={search} 
            onChange={({ target: { value } }) => setSearch(value)}
        />
        <SearchIcon src={searchIcon} />
    </SearchInputContainer>
)

const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 15px 33px;
    padding: 5px 10px;
	border: 1px #ccc solid;
	border-radius: 4px;
	background: #fff;
	height: 39px;
	transition: all 400ms ease-in-out;
	width: 84%;
`

const SearchInput = styled.input`
    padding: 3px 2px 1px 2px;
	background: none;
	border: none;
	color: #000;
	font-size: 16px;
	width: 94%;
    &:focus {
        outline: none;
    }
`

const SearchIcon = styled.img`
    width: 17px;
    height: 17px;
`

export default Search