import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { createFormTemplate, createFormInput, submitForm } from "services/paymentForm";
import { useFetch } from "./useFetch";
import { base64Decode, decryptParams } from "services/crypto";
import { loader } from "helper/misc";

import { InfoContext } from "state/Info/Info";

const useProceedToPay = () => {
    const {processOrder} = useFetch();
    const { order } = useContext(InfoContext);
    const {orderId} = useParams();
    const navigate = useNavigate();

    const proceedToPay = async({payMethod, data, disableSubmit, isSaved, methodType }) => {
        if (order.is_test) {
            navigate(`/pay/${orderId}/txn-simulator`, {state: {payMethod, methodType}})
            return
        }

        loader.show();
        const response = await processOrder({pg: payMethod, data, save: isSaved});
        loader.hide();

        if (disableSubmit) {
            return response
        }

        const formSubmitUrl = base64Decode(response.data.url);
        createFormTemplate(formSubmitUrl);
        const orderData = decryptParams(response.data.param);

        for (const inputName in orderData) {
            createFormInput(inputName, orderData[inputName])
        }

        submitForm();
    }
    
    return {
        proceedToPay
    }
}

export default useProceedToPay