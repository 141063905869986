import styled from "styled-components";

export const PaymentContainer = styled.div`
    display: flex;
    height: 76vh;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    justify-content: center;
    max-width: 750px;
    margin: 20px auto 0;
    border-radius: 8px;
    border-left: ${props => `6px solid ${props.theme.colors.primary}`};
    @media screen and (max-width: 768px) {
        border-left: unset;
        margin: unset;
        flex-direction: column
    }
`

export const RightSideContainer = styled.div`
    height: 76vh;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    flex: 1;
    overflow-y: scroll;
`