import styled from "styled-components";

export const PrimaryPayBtn = styled.button.attrs(props => ({
    'data-testid': props.dataTestId || 'pay-btn',
    isDisabled: props.isDisabled,
    background: props.background
}))`
    background: ${props => props.background || props.theme.colors.secondary};
    color: ${props => props.isDisabled ? 'rgba(255,255,255,0.35)' : 'white'};
    width : 100%;
    padding: 1px 4px;
    border-radius: 4px;
    border: 0;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    cursor: ${props => props.isDisabled ? '' : 'pointer'};
    pointer-events: ${props => props.isDisabled && 'none'};
`