import { PaymentOptionsContainer } from "./styled";
import PaymentOptions from "../../components/PaymentOptions/PaymentOptions";
import withPaymentCard from "hoc/withPaymentCard";

const PaymentOptionsRoute = () => {

  return (
      <PaymentOptionsContainer>
        <PaymentOptions />
      </PaymentOptionsContainer>
  );
}

export default withPaymentCard(PaymentOptionsRoute)