import { useContext, useEffect } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { MAX_MIN_CONFIG } from "../../config/static";
import { VALIDATION_REGEX } from "../../config/static";

import { InputContext } from "state/Input/Input";

const UpiInput = ({ handles }) => {
    const { upiId, setUpiId, selectedUpiHandle, setSelectedUpiHandle, selectedPaymentOption } = useContext(InputContext);

    useEffect(() => {
        return () => setUpiId('')
    }, []); // eslint-disable-line

    const getUpiHandle = (upiId) => upiId.split('@')[1];

    const inputValidation = (id) => {
        if (selectedPaymentOption.code !== 'any') {
            return new RegExp(VALIDATION_REGEX.UPI_EXCEPT_ANY).test(id)
        }
        return new RegExp(VALIDATION_REGEX.UPI_ANY).test(id)
    }

    const handleOnChangeUpiId = ({target}) => {
        const validation  = inputValidation(target.value);
        if (!validation) {
            return
        }
        setUpiId(target.value);
        if (!handles) {
            const upiHandle = getUpiHandle(target.value);
            setSelectedUpiHandle(upiHandle || '')
        }
    }

    return (
        <Container>
            <Input 
                data-testid="upi-input" 
                value={upiId}
                onChange={handleOnChangeUpiId} 
                maxLength={MAX_MIN_CONFIG.MAX_UPI_ID_LENGTH} 
                placeholder="Enter UPI ID" 
            />
            {handles &&
                <div className="mx-0 d-flex flex-row">
                    <p className="my-auto">@</p>
                    <Dropdown style={{ flex: 1 }} onSelect={(e) => setSelectedUpiHandle(e)}>
                        <Dropdown.Toggle className={`w-100 border-0 bg-transparent text-dark ${!selectedUpiHandle && 'text-muted'}`} variant="light" id="dropdown-basic" size="sm">
                            {`${selectedUpiHandle || handles[0]}`}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                        {
                            handles?.map(handle => <Dropdown.Item style={{ fontWeight: 600 }} key={handle} eventKey={handle}>{`@${handle}`}</Dropdown.Item>)
                        }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-top: 19px;
    border: 1px #ccc solid;
    border-radius: 4px;
    height: 39px;
    width: 100%;
`

const Input = styled.input`
    border: none;
    background: none;
    font-size: 14px;
    padding-top: 4px;
    /* width: 62%; */
    flex: 1;
    &:focus {
        outline: none;
    }
`

export default UpiInput;