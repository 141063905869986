// Footer
export const nortonSecured = `${process.env.REACT_APP_BASE_PATH}/assets/images/footer/nortonSecured.svg`;
export const verifiedByVisa = `${process.env.REACT_APP_BASE_PATH}/assets/images/footer/verifiedByVisa.svg`;
export const masterCardSecure =  `${process.env.REACT_APP_BASE_PATH}/assets/images/footer/mastercardSecure.svg`;
export const rupay = `${process.env.REACT_APP_BASE_PATH}/assets/images/footer/rupay.svg`;
export const amexSafekey = `${process.env.REACT_APP_BASE_PATH}/assets/images/footer/amexSafekey.svg`;
export const pciDss = `${process.env.REACT_APP_BASE_PATH}/assets/images/footer/pciDss.svg`;

export const footerImages = {nortonSecured, verifiedByVisa, masterCardSecure, rupay, amexSafekey, pciDss}
// Generic Icons
export const netBankingIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/netbanking.svg`;
export const upiIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/upi.svg`;
export const walletIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/wallet.svg`;
export const cardIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/card.svg`;
export const emiIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/emi.svg`;
export const close = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/close.svg`;
export const creditCard = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/cc.svg`;
export const plusIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/plus.svg`;
export const backArrow = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/back.svg`;
export const verifyIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/verify.svg`;
export const settingsIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/settings.svg`;
export const deleteIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/delete.svg`;

// Card Icons
export const masterCardLogo = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/card/mastercard.svg`;
export const visaLogo = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/card/visa.svg`;
export const rupayLogo = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/card/rupay.svg`;
export const amexLogo = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/card/amex.svg`;
export const dinersLogo = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/card/diners.svg`;

export const scanAndPayIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/bharatqr.svg`;
export const phonePeIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/phonepe.svg`;
export const payTmIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/paytm.svg`;
export const gPayNewIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/gpay.svg`;
export const lazypayIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/lazypay.svg`;
export const rewardsIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/twid.svg`;
export const bhimIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/bhim.svg`;
export const bhimSmallIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/bhimSmall.svg`;
export const poweredByUpiIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/upiPowered.svg`;
export const dummyQrImage = `${process.env.REACT_APP_BASE_PATH}/assets/images/misc/dummyQrPartial.png`;
export const bhimUpiLogo = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/bhimUpi.svg`;

export const checkBoxChecked = `${process.env.REACT_APP_BASE_PATH}/assets/images/misc/checked.svg`;
export const checkboxUnchecked = `${process.env.REACT_APP_BASE_PATH}/assets/images/misc/unChecked.svg`;
export const arrowRight = `${process.env.REACT_APP_BASE_PATH}/assets/images/misc/arrowRight.svg`;
export const dummyQr = `${process.env.REACT_APP_BASE_PATH}/assets/images/misc/dummyQr.svg`;
export const searchIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/search.png`;
export const language = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/language.svg`;
export const bankAltIcon = `${process.env.REACT_APP_BASE_PATH}/assets/images/icons/generic/bankAlt.svg`;

export const lazyPayBenifits1 = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/lazyPay/benefits1.svg`;
export const lazyPayBenifits2 = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/lazyPay/benefits2.svg`;
export const lazyPayBenifits3 = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/lazyPay/benefits3.svg`;
export const lazyPayBenifits4 = `${process.env.REACT_APP_BASE_PATH}/assets/images/logo/lazyPay/benefits4.svg`;

export const errorImage500 = `${process.env.REACT_APP_BASE_PATH}/assets/images/misc/500Error.svg`;