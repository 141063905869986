import styled from "styled-components";

export const UpiInputContainer = styled.div`
    margin-bottom: 17px;
`

export const UpiPaymentForm = styled.div`
    margin: 29px 0 0 15px;
`

export const ConsentBoxContainer = styled.div`
    padding-top: 19px;
    padding-bottom: 8px;
`

export const BhimFooterContainer = styled.div`
    margin: 20px auto 0;
    text-align: center;
`

export const VpaVerifiedContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
`

export const VpaVerifiedTextContainer = styled.div`
    display: flex;
`