import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    UpiInputContainer, 
    UpiPaymentForm,
    VpaVerifiedContainer,
    VpaVerifiedTextContainer
} from './styled.js';
import UpiInput from 'components/Misc/UpiInput.js';
import { InputContext } from 'state/Input/Input.js';
import { encryptData } from 'services/crypto.js';
import ProceedBtn from 'components/Misc/ProceedBtn.js';
import { ErrorText, SuccessText } from 'components/Styled/Text.js';
import { verifyIcon, upiIcon } from 'config/images.js';

import useProceedToPay from "hooks/useProceedToPay.js";
import { useFetch } from 'hooks/useFetch.js';

const SelectedUpi = ({ Footer, upiHandles }) => {
    const [isUpiIdVerified, setIsUpiIdVerified] = useState(false);
    const [vpaAccName, setVpaAccName] = useState('');
    const [isVpaIncorrect, setIsVpaIncorrect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { verifyUpiId } = useFetch();

    const { selectedUpiHandle, setSelectedUpiHandle, upiId, selectedPaymentOption } = useContext(InputContext);
    const { proceedToPay } = useProceedToPay();
    const UpiBtnText = isUpiIdVerified ? 'PROCEED TO PAY' : 'VERIFY';
    const completeUpiId = `${upiId.split('@')[0]}@${selectedUpiHandle}`;

    useEffect(() => {
        return () => setSelectedUpiHandle(null);
    }, []); //eslint-disable-line

    useEffect(() => {
        if (isUpiIdVerified) {
            setIsUpiIdVerified(false);
            setVpaAccName('')
        }
        isVpaIncorrect && setIsVpaIncorrect(false);
    }, [upiId, selectedUpiHandle]) //eslint-disable-line

    const handleProceedToPay = async() => {
        const jsonVpa = JSON.stringify({
            vpa: completeUpiId
        })
        const encryptedUpiId = encryptData(jsonVpa);
        const state = {
            upiAppName: selectedPaymentOption.title,
            upiAppImg: selectedPaymentOption.image || upiIcon
        }
        const response = await proceedToPay({ payMethod: 'UPI', data: encryptedUpiId, disableSubmit: true, methodType: completeUpiId });
        if (response?.data?.url && response?.data?.param) {
            navigate('loader', { state })
        }
    }

    const handleVerifyUpi = async () => {
        setIsLoading(true);
        const verification = await verifyUpiId(completeUpiId);
        setIsLoading(false);
        if (!verification.status || !verification.data.isVPAValid) {
            setIsVpaIncorrect(true)
            return
        }
        setIsUpiIdVerified(true);
        setVpaAccName(verification.data.payerAccountName)
    }

    const handleOnSubmit = () => {
        if (!isUpiIdVerified) {
            handleVerifyUpi();
            return
        }
        handleProceedToPay();
    }

    const VpaVerified = () => (
        <VpaVerifiedContainer>
            <SuccessText>{vpaAccName}</SuccessText>
            <VpaVerifiedTextContainer>
                <SuccessText className='me-1'>VERIFIED</SuccessText>
                <img src={verifyIcon} alt="verified"/>
            </VpaVerifiedTextContainer>
        </VpaVerifiedContainer>
    )

    return (
        <UpiPaymentForm>
            <UpiInputContainer>
                <UpiInput handles={upiHandles} />
            </UpiInputContainer>
            {isVpaIncorrect && <ErrorText>UPI ID is incorrect. Please try again</ErrorText>}
            {!!vpaAccName && <VpaVerified/>}
            <ProceedBtn
                text={UpiBtnText}
                onClick={handleOnSubmit}
                isDisabled={!selectedUpiHandle}
                isLoading={isLoading}
            />
            {Footer && <Footer />}
        </UpiPaymentForm>
    )
}

export default SelectedUpi;