import { createContext, useEffect, useState } from "react";
import {aesDecrypt} from 'services/crypto'

export const BasicContext = createContext();

const BasicProvider = ({children}) => {
    const [apiUrl, setApiUrl] = useState('');
    
    useEffect(() => {
        const hostname = window.location.hostname
        const getApiUrl = async() => {
            const response = await fetch(`${process.env.REACT_APP_BASE_PATH ?? '' }/assets/api-url.txt`, {
                headers: {
                    "Content-Type": "application/text"
                }
            })
            const urls = await response.text();
            const decryptedUrls = JSON.parse(aesDecrypt(urls));
            const apiUrl = decryptedUrls.find(url => url.frontend === hostname);
            !apiUrl && console.error('Unable to get api url')
            setApiUrl(apiUrl.backend)
        }
    
        getApiUrl()
    }, [])

    const values = {
        apiUrl
    }

    return (
        <BasicContext.Provider value={values}>
            {children}
        </BasicContext.Provider>
    )
}

export default BasicProvider;