import styled from "styled-components";
import { P4, P5 } from "../../components/Styled/Text";

export const ScanQrText = styled(P4)`
    margin-bottom: 0;
	line-height: 1.36;
	letter-spacing: 0.2px;
	text-align: center;
	color: #2e2e2e;
	padding-bottom: 36px;
`

export const QrContainer = styled.div`
    margin: 68px 0 50px 15px;
`

export const QrImageContainer = styled.div`
	width: 96px;
	height: 96px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 35px;
    
`

export const UpiLogosContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`

export const UpiLogo = styled.img`
    margin-left: 12px;
`

export const UpiText = styled(P5)`
    margin-bottom: 0;
	float: left;
	color: #a3a3a3;
	line-height: normal;
`