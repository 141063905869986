export const calculateConvenienceFeeAmount = (type, fee, orderAmount) => {
    if (type === 'percentage') {
        return (orderAmount / 10000) * fee;
    }
    if (type === 'flat') {
        return fee
    }
    if (type == 'none') {
        return null
    }
}