import { useContext, useEffect } from "react";

import { NewCardFieldContainer, NewCardInputLabel } from "../styled"
import { Input1 } from "../../Styled/Misc"
import { ErrorText } from "../../Styled/Text";

import { VALIDATION_REGEX, CARD_CONFIG, ERROR_MSSG } from "config/static";

import { CardContext } from "state/Card";

const Cvv = ({hideLabel, style}) => {
    const { cvv, setCvv, cvvRef, cvvError, setCvvError, cardNameRef } = useContext(CardContext);

    useEffect(() => {
        if (cvv.length === CARD_CONFIG.MAX_CVV_LENGTH && !!cardNameRef.current) {
            cardNameRef.current.focus()
        }
    }, [cvv, cardNameRef])

    const handleOnChangeCvv = ({ target: { value } }) => {
        const cvvRegex = new RegExp(VALIDATION_REGEX.CVV);
        if (!cvvRegex.test(value)) {
            if (value !== '') {
                return
            }
        }

        if (value.length > CARD_CONFIG.MAX_CVV_LENGTH) {
            return
        }
        setCvv(value);

    }

    const runCvvValidation = (cvv) => {
        if (!cvv) {
            setCvvError(ERROR_MSSG.CARD_CVV_EMPTY);
            return;
        }

        if (cvv.length < CARD_CONFIG.MIN_CVV_LENGTH || cvv.length > CARD_CONFIG.MAX_CARD_NO_LENGTH) {
            setCvvError(ERROR_MSSG.INVALID_CVV);
            return;
        }
        setCvvError('');
    }

    return (
        <NewCardFieldContainer style={style} data-testid="card-cvv" className="col-6">
            {!hideLabel && <NewCardInputLabel for="cvv">CVV</NewCardInputLabel>}
            <Input1
                id="cvv"
                type="password"
                autoComplete="cc-csc"
                placeholder="Enter CVV"
                data-testid="card-cvv-input"
                value={cvv}
                onChange={handleOnChangeCvv}
                onBlur={() => runCvvValidation(cvv)}
                ref={cvvRef}
            />
            <ErrorText data-testid="card-cvv-error">{cvvError}</ErrorText>
        </NewCardFieldContainer>
    )
}

export default Cvv