import { useContext, useEffect } from "react";
import {
    ModalContainer,
    Modal,
    HeadingContainer,
    Heading,
    SubHeading,
    SubContainer
} from "./styled";
import { close, cardIcon, deleteIcon } from "config/images";
import { CardContext } from "state/Card";

import PaymentOption from "components/Payment/PaymentOption/PaymentOption";
import { getSavedCardTitle, getCardLogo } from "components/Card/helper";

import { useFetch } from 'hooks/useFetch';

const SavedOptions = () => {
    const { setShowSavedOptions, savedCards, setSavedCards } = useContext(CardContext);
    const { deleteSavedCard } = useFetch();
    useEffect(() => {
        !savedCards.length && setShowSavedOptions(false)
    }, [savedCards])

    const handleDeleteCard = async(token) => {
        const deleteCard = await deleteSavedCard(token);
        if (deleteCard.status) {
            const updatedCards = savedCards.filter(card => card.token !== token);
            setSavedCards(updatedCards)
        }
    }

    return (
        <ModalContainer>
            <Modal>
                {/* Heading */}
                <HeadingContainer>
                    <SubContainer>
                        <Heading>Manage Saved Options</Heading>
                        <img style={{ cursor: 'pointer' }} src={close} alt="close" onClick={() => setShowSavedOptions(false)} />
                    </SubContainer>
                    {/* Sub Heading */}
                    <SubHeading>You can now manage your saved payment options from the list below. The saved option will no longer be visible once deleted.</SubHeading>
                </HeadingContainer>

                {/* Saved Options */}
                {
                    savedCards?.map((card, i) => (
                        <div>
                            <PaymentOption
                                key={i}
                                code={card.token}
                                name={getSavedCardTitle(card)}
                                icon={{left: getCardLogo(card.brand), altLeft: cardIcon, right: deleteIcon}}
                                onClickRight={() => handleDeleteCard(card.token)}
                            />
                        </div>
                    ))
                }
            </Modal>
        </ModalContainer>
    )
}

export default SavedOptions