import { masterCardLogo, visaLogo, rupayLogo, amexLogo, dinersLogo } from "config/images";
import { CARD_CONFIG, CARD_TYPE } from "config/static";

/**
    * @description Splits any given string into an array of strings with specified character length
    * @param  {string} string String that needs to be split
    * @param  {number} size Size of each string in the collection
   */
export const chunk = (string, size) => {
    return string.match(new RegExp('.{1,' + size + '}', 'g'))
}

/**
 * @description Validate card no
 * @param  {string} cardNo
 * @returns {boolean} if valid
 */
export const cardNoValidator = (cardNo) => {
    let nCheck = 0;
    let bEven = false;
    for (var n = cardNo.length - 1; n >= 0; n--) {
		var cDigit = cardNo.charAt(n),
			  nDigit = parseInt(cDigit, 10);

		if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

		nCheck += nDigit;
		bEven = !bEven;
	}
    return (nCheck % 10) == 0; // eslint-disable-line
}


export const getCardLogo = (cardType) => {

    const logos = {
        'MAST': masterCardLogo,
        'VISA': visaLogo,
        'AMEX': amexLogo,
        'DINER': dinersLogo,
        'RUPAY': rupayLogo,
        // 'MAES': maestroLogo,
    }

    return logos[cardType]
}

export const sanitizeCardNo = cardNo => cardNo.replaceAll(CARD_CONFIG.CARD_NO_SEPARATOR, '')

export const sanitize = {
    cardNo: cardNo => cardNo.replaceAll(CARD_CONFIG.CARD_NO_SEPARATOR, ''),
    expiry: expiry => expiry.replaceAll(CARD_CONFIG.EXPIRY_SEPARATOR, '')
}

export const getCardInfo = (cardNo) => {
    // Visa
    if (cardNo.startsWith('4')) {
        return {
            type: 'visa',
            cardNoLength: 16,
            cvvLength: 3
        }
    }
    // Amex
    if (cardNo.startsWith('34') || cardNo.startsWith('37')) {
        return {
            type: 'amex',
            cardNoLength: 15,
            cvvLength: 4
        }
    }
    // Mastercard
    if (['51', '52', '53', '53', '54', '55'].includes(cardNo.substring(0,2))) {
        return {
            type: 'mast',
            cardNoLength: 16,
            cvvLength: 3
        }
    }
    // Diner
    if (['300', '301', '302', '303', '304', '305'].includes(cardNo.substring(0,3)) || ['36', '38'].includes(cardNo.substring(0,2))) {
        return {
            type: 'diner',
            cardNoLength: 14,
            cvvLength: 3
        }
    }
    // Rupay
    if (['60', '65', '81', '82'].includes(cardNo.substring(0,2)) || cardNo.startsWith('508') ) {
        return {
            type: 'rupay',
            cardNoLength: 16,
            cvvLength: 3
        }
    }
}

export const getLast4Digits = (cardNo) => cardNo.slice(-4)

export const getSavedCardTitle = (cardInfo) => {
    return `${cardInfo.issuing_bank || ""} ${CARD_TYPE[cardInfo.type]} **** ${getLast4Digits(cardInfo.card_no)}`
}

