import { useContext, useEffect } from "react";

import { NewCardInputLabel, NewCardFieldContainer } from "../styled";
import { Input1 } from "../../Styled/Misc";
import { ErrorText } from "../../Styled/Text";
import { chunk, sanitize } from "../helper";

import { CARD_CONFIG, VALIDATION_REGEX, ERROR_MSSG } from "../../../config/static";

import { CardContext } from "state/Card";

const Expiry = () => {

    const { expiry, setExpiry, expiryError, setExpiryError, expiryRef, cvvRef } = useContext(CardContext);
    let sanitizedExpiry; // eslint-disable-line

    useEffect(() => {
        // Validate expiry date
        if (sanitize.expiry(expiry).length === 4) {
            const validation = runExpiryValidation(sanitize.expiry(expiry));
            validation && cvvRef.current.focus();
        }
    }, [expiry]) // eslint-disable-line

    const handleOnChangeExpiry = async ({ target: { value } }) => {
        const separator = CARD_CONFIG.EXPIRY_SEPARATOR
        sanitizedExpiry = sanitize.expiry(value);
        const expiryRegex = new RegExp(VALIDATION_REGEX.EXPIRY);

        if (!expiryRegex.test(value.replaceAll('/', ''))) {
            if (value !== '') {
                return
            }
        }
        // Format month
        if (value.length === 1 && !["0", "1"].includes(value)) {
            setExpiry(`0${value}/` || '');
            return;
        }

        if (value.length === 2) {
            if (value.charAt(0) === '0' && value.charAt(1) === '0') {
                return;
            }
            if (value.charAt(0) === '1' && !["0", "1", "2"].includes(value.charAt(1))) {
                return;
            }
        }

        // Validate expiry length
        if (value.replaceAll(separator, '').length > 4) {
            return
        }
        const splitExpiry = chunk(value.replaceAll(separator, ''), 2)?.join(separator);
        setExpiry(splitExpiry || '');
    }

    const runExpiryValidation = (value) => {
        const isExpiryInvalid = expiryInValidator(value);
        if (isExpiryInvalid) {
            setExpiryError(isExpiryInvalid);
            return false;
        }
        setExpiryError('');
        return true
    }

    return (
        <NewCardFieldContainer className="col-6" data-testid='card-expiry'>
            <NewCardInputLabel for="expiry">Expiry</NewCardInputLabel>
            <Input1
                id='expiry'
                placeholder="MM/YY"
                autoComplete="cc-exp"
                data-testid='expiry-input'
                value={expiry}
                onChange={handleOnChangeExpiry}
                onBlur={() => runExpiryValidation(sanitize.expiry(expiry))}
                ref={expiryRef}
            />
            {expiryError && <ErrorText data-testid='expiry-error-msg'>{expiryError}</ErrorText>}
        </NewCardFieldContainer>
    )
};

const expiryInValidator = (expiry) => {
    if (!expiry) {
        return ERROR_MSSG.CARD_EXPIRY_EMPTY
    }

    if (expiry.length < 4) {
        return "Invalid Expiry Date"
    }

    const expiryMonth = parseInt(expiry.substr(0, 2));
    const expiryYear = parseInt(expiry.substr(2, 2));
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = parseInt(today.getFullYear().toString().slice(-2));
    const maxExpiryYear = CARD_CONFIG.MAX_EXPIRY_YEAR;

    if (expiryYear >= currentYear) {
        if (expiryYear === currentYear) {
            if (expiryMonth >= currentMonth) {
                return false;
            }
            return ERROR_MSSG.CARD_EXPIRY_PAST
        }
        if (parseInt(expiryYear) > maxExpiryYear) {
            return "Invalid Expiry Date"
        }
        return false
    }
    else if (expiryYear < currentYear) {
        return ERROR_MSSG.CARD_EXPIRY_PAST
    }
}

export default Expiry;