import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Footer from "components/Footer/Footer";
import { PaymentContainer } from "layout/PaymentCard/styled";
import { P1, P3 } from 'components/Styled/Text';
import {
    AmountText,
    CheckStatus,
    Container,
    OtherText,
    PrimaryText,
    SecondaryText,
    UpiImage,
    SubFooter,
    Timer
} from './styled';
import ProceedBtn from 'components/Misc/ProceedBtn';

import useCountdown from 'hooks/useCountdown';
import { InfoContext } from 'state/Info/Info';
import { poweredByUpiIcon, upiIcon } from 'config/images';
import { UpiLogos } from 'routes/Qr/Qr';
import { useFetch } from 'hooks/useFetch';
import { REDIRECT_CALLBACK_ACTIONS } from 'config/static';

const UpiLoader = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const [checkStatusTimer, setCheckStatusTimer] = useState(0);
    const [isCheckingStatus, setIsCheckingStatus] = useState(false);
    const [minutes, seconds] = useCountdown(300);
    const { order: { amount } } = useContext(InfoContext);
    const { state } = useLocation();
    const { getUpiTxnStatus, redirectToCallBack, redirectOnCancellation } = useFetch();
    const disableCheckStatusSeconds = 20;

    const updateTxn = (upiState) => {
        redirectToCallBack(REDIRECT_CALLBACK_ACTIONS.UPI_TXN, upiState)
    };

    const checkTxnStatus = async () => {
        const { data: { txn_status } } = await getUpiTxnStatus(!!state.qrImage);
        if (txn_status) {
            updateTxn('success')
        }
    }

    const handleOnGoBack = () => {
        navigate(`/pay/${orderId}`)
    }

    const clearNavHistory = () => {
        window.history.pushState(null, null, window.location.pathname);
    }

    useEffect(() => {
        const handleGoBack = () => {
            clearNavHistory()
            const confirmGoBack = window.confirm('Are you sure you want to cancel the transaction?');
            if (confirmGoBack) {
                redirectOnCancellation()
            }
        }

        clearNavHistory()
        window.addEventListener('popstate', handleGoBack);
        return () => {
            window.removeEventListener('popstate', handleGoBack);
        };
    }, []) // eslint-disable-line

    useEffect(() => {
        const updateTimer = async() => {
            if (!(seconds % 30)) {
                setCheckStatusTimer(0);
                await checkTxnStatus();
            }
            if (!seconds && !minutes) {
                redirectOnCancellation()
            }
        
        }

        updateTimer()    
    }, [seconds]) // eslint-disable-line

    useEffect(() => {
        if (!checkStatusTimer) {
            return
        }
        const timer = setTimeout(() => {
            setCheckStatusTimer(checkStatusTimer - 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [checkStatusTimer])

    const handleOnStatusCheck = async () => {
        setIsCheckingStatus(true);
        await checkTxnStatus();
        setIsCheckingStatus(false);
        setCheckStatusTimer(disableCheckStatusSeconds);
    }

    return (
        <>
            <PaymentContainer>
                <Container>
                    {<img className='mb-3' width={100} src={state.upiAppImg || upiIcon} alt="UPI App" onError={({currentTarget}) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = upiIcon
                    }} />}
                    {
                        state?.qrImage && <img width={220} src={`data:image/png;base64,${state.qrImage}`} alt="QR Code" />
                    }
                    {state.upiAppImg &&
                        <PrimaryText>
                            {`Open ${state?.upiAppName || 'your UPI'} mobile app and approve payment of INR `}<AmountText>{amount / 100}</AmountText>
                        </PrimaryText>
                    }
                    {
                        state.qrImage && (
                            <PrimaryText>
                                {`Scan the QR code and pay INR `}<AmountText>{amount / 100}</AmountText> using apps enabled with UPI QR
                            </PrimaryText>
                        )

                    }
                    <P1 className='mt-2'>Approve payment within <Timer>{`${minutes}:${seconds}`}</Timer></P1>
                    {
                        state?.qrImage && (
                            <UpiLogos />
                        )
                    }
                    <CheckStatus >
                        <SecondaryText>Please click 'CHECK STATUS' button if you are not automatically redirected to merchant website</SecondaryText>
                        <div className="mt-3 w-50">
                            <ProceedBtn
                                isDisabled={!!checkStatusTimer}
                                text={!!checkStatusTimer ? `Check in ${disableCheckStatusSeconds} SEC` : 'Check Status'}
                                onClick={handleOnStatusCheck}
                                isLoading={isCheckingStatus}
                            />
                        </div>
                    </CheckStatus>
                    <SubFooter>
                        {state?.upiAppName && <UpiImage src={poweredByUpiIcon} />}
                        <P3>Can't pay with UPI?</P3>
                        <OtherText onClick={handleOnGoBack}>Choose other payment options</OtherText>
                    </SubFooter>
                </Container>
            </PaymentContainer>
            <Footer />
        </>
    )
}

export default UpiLoader