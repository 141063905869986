import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentCard from "../../layout/PaymentCard/PaymentCard";
import SelectedPayment from "../../layout/SelectedPayment/SelectedPayment";
import {
    QrContainer,
    QrImageContainer,
    ScanQrText,
    UpiLogosContainer,
    UpiLogo,
    UpiText
} from './styled.js'
import { gPayNewIcon, payTmIcon, phonePeIcon, bhimIcon } from "../../config/images";
import { useFetch } from "hooks/useFetch";
import ProceedBtn from "components/Misc/ProceedBtn";

import { scanAndPayIcon, dummyQr } from "../../config/images";

const QrRoute = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { generateQr } = useFetch();
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async() => {
        setIsLoading(true)
        const {data} = await generateQr();
        setIsLoading(false);

        navigate(`/pay/${orderId}/upi/loader`, {state: {
            qrImage: data.qr_image
        }})
    }

    return (
        <PaymentCard>
            <SelectedPayment name="BHARAT QR" icon={{left: scanAndPayIcon}}>
                <QrContainer>
                    <QrImageContainer>
                        <img src={dummyQr} alt="qr code" />
                    </QrImageContainer>
                    <ScanQrText>Scan the QR code using apps enabled with Bharat QR</ScanQrText>
                    <ProceedBtn text="GENERATE QR" onClick={handleOnClick} isLoading={isLoading}/>
                    <UpiLogos />
                </QrContainer>
            </SelectedPayment>
        </PaymentCard>
    )
};

export const UpiLogos = () => {
    return (
        <UpiLogosContainer>
            <UpiText>100+ UPI Apps supported</UpiText>
            <div>
                <UpiLogo src={gPayNewIcon} />
                <UpiLogo src={payTmIcon} />
                <UpiLogo src={phonePeIcon} />
                <UpiLogo src={bhimIcon} />
            </div>
        </UpiLogosContainer>
    )
}

export default QrRoute;

