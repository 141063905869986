import { useParams, useNavigate } from 'react-router-dom'
import { useFetch } from 'hooks/useFetch.js'

import {
    ScanAndPayContainer,
    ScanPrimaryText,
    ScanSecondaryText,
    GenerateButton
} from './styled.js'

import { dummyQrImage, poweredByUpiIcon } from 'config/images'

const ScanAndPay = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { generateQr } = useFetch();

    const handleOnGenerate = async () => {
        const { data } = await generateQr();

        navigate(`/pay/${orderId}/upi/loader`, {
            state: {
                qrImage: data.qr_image
            }
        })
    }

    return (
        <ScanAndPayContainer>
            <div>
                <ScanPrimaryText>Pay instantly by QR code</ScanPrimaryText>
                <ScanSecondaryText>Scan & Pay using your preferred UPI App</ScanSecondaryText>
                <img src={poweredByUpiIcon} alt="Powered by UPI"/>
            </div>
            <div style={{ position: 'relative' }}>
                <img width={110} src={dummyQrImage} alt="QR Code" />
                <GenerateButton onClick={handleOnGenerate} style={{ position: 'absolute', top: 43, left: -13 }}>
                    Generate QR
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="currentColor" stroke="currentColor" strokeWidth="0.3" d="M14.8 12l-.485-.499-4.19-4.301-1.325.998L12.504 12 8.8 15.802l1.325.998 4.19-4.301z"></path></svg>
                </GenerateButton>
            </div>
        </ScanAndPayContainer>
    )
}

export default ScanAndPay