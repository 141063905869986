import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 45px 20px 41px;
    align-items: center;
    cursor: pointer;
	border-bottom: 1px #f4f4f4 solid;
	border-left: 4px #ffffff solid;
	font-size: 13px;
	font-weight: 500;
	line-height: normal;
	color: #2e2e2e;
	transition: all 400ms ease-in-out;
`

export const DownIndicator = styled.span`
	background: #f5a623;
	font-size: 10px;
	color: #fff;
	text-align: center;
	padding: 5px 5px;
	border-radius: 2px;
	height: 20px;
	width: 98px;
	position: relative;


	&:before {
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-right: 6px solid #f5a623;
		margin-left: -11px;
		margin: 5px 0 0 50%;
		margin-left: 50%;
		float: left;
		position: absolute;
		top: 30%;
		left: -57%;
		transform: translateY(-50%);
		content: "";
	}
`