import { Container, DownIndicator } from './styled';
import { P2, P4 } from '../../../components/Styled/Text.js';

import { arrowRight } from '../../../config/images';

const PaymentOption = ({ onClick, onClickRight, code, icon, name, subHeader, isDown }) => (
    <Container style={{ cursor: !onClick && 'default' }} onClick={onClick} data-testid={code}>
        <img data-testid={`logo-${code}`} width={20} src={icon.left} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = icon.altLeft
        }} />
        <div className='me-auto ms-2' style={{ maxWidth: '65%' }}>
            <P2 className="mb-0">{name}</P2>
            {subHeader && <P4 className="mb-0" style={{ color: '#8E8E8E' }}>{subHeader}</P4>}
        </div>
        {!isDown ? <img style={{cursor: 'pointer'}} onClick={onClickRight} data-testid={`select-icon`} src={icon.right || arrowRight} alt="Go" /> : <DownIndicator>Currently Down</DownIndicator>}
    </Container>
)

export default PaymentOption;