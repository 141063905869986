import SelectedPayment from "layout/SelectedPayment/SelectedPayment";
import { SelectedPaymentSubContainer } from "layout/SelectedPayment/SelectedPayment";
import { P4 } from "components/Styled/Text";
import { ProceedBtn } from "routes/PaymentOptions/styled";
import { TEXT } from "config/static";
import useProceedToPay from "hooks/useProceedToPay.js";
import {encryptData} from 'services/crypto';

const SelectedWallet = ({wallet, setSelectedWallet, altIcon}) => {
    const { proceedToPay } = useProceedToPay();

    const handleOnPay = () => {
        const data = {
            bankcode: wallet.code
        };
        const stringifiedData = JSON.stringify(data)
        const encryptedData = encryptData(stringifiedData)

        proceedToPay({payMethod: 'WALLETS', data: encryptedData, methodType: wallet.code});
    }
    
    return (
        <SelectedPayment key={wallet.code} code={wallet.code} name={wallet.title} icon={{left: wallet.image || altIcon, altLeft: altIcon}} onClose={() => setSelectedWallet("")}>
            <SelectedPaymentSubContainer>
                <P4>{TEXT.SELECTED_WALLET}</P4>
                <ProceedBtn onClick={handleOnPay}>{TEXT.PAY_BTN}</ProceedBtn>
            </SelectedPaymentSubContainer>
        </SelectedPayment>
    )
}

export default SelectedWallet;