const mapPath = (pathName) => {
    return pathProps[pathName] || pathProps.default
}

const pathProps = {
    'default': {
        leftSideCard: {
            header: "Choose a payment option",
        },
        rightSideHeader: 'PAYMENT OPTIONS'
    },
    'cards': {
        leftSideCard: {
            header: "Enter new card",
        },
        rightSideHeader: 'Cards (Credit/Debit)'
    },
    'emi/cc': {
        leftSideCard: {
            header: "Choose EMI plan & enter card details",
        },
        rightSideHeader: 'EMI WITH Credit Card'
    },
    'emi/dc': {
        leftSideCard: {
            header: "Choose EMI plan & enter card details",
        },
        rightSideHeader: 'EMI WITH Debit Card'
    },
    'emi': {
        leftSideCard: {
            header: "Choose an EMI option",
        },
        rightSideHeader: 'All EMI OPTIONS'
    },
    'wallets': {
        leftSideCard: {
            header: "Choose a wallet",
        },
        rightSideHeader: 'WALLETS'
    },
    'netbanking': {
        leftSideCard: {
            header: "Choose or search for a bank",
        },
        rightSideHeader: 'BANKS'
    },
    'upi': {
        leftSideCard: {
            header: "Enter Any UPI ID",
        },
        rightSideHeader: 'UPI Options'
    },
    'qr': {
        leftSideCard: {
            header: "Click on Generate QR",
        },
        rightSideHeader: 'Scan & Pay'
    },
    'phonePe': {
        leftSideCard: {
            header: "Enter PhonePe registered UPI ID",
        },
        rightSideHeader: 'PHONEPE OPTIONS'
    },
    'lazypay': {
        leftSideCard: {
            header: "Enter your details",
        },
        rightSideHeader: 'QUICK OPTIONS'
    },
    'gPay': {
        leftSideCard: {
            header: "Enter Google Pay registered number",
        },
        rightSideHeader: 'GOOGLE PAY OPTIONS'
    },
    'payByRewards': {
        leftSideCard: {
            header: "Enter the Mobile Number",
        },
        rightSideHeader: 'Pay with Rewards'
    }
}

export default mapPath;