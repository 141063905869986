import React, {useContext} from "react";
import { ThemeProvider } from "styled-components";
import { InfoContext } from "../state/Info/Info";

const Theme = ({children}) => {
    const {group: {colors}} = useContext(InfoContext);
    const theme = {
        colors: {
            primary: colors?.primary,
            secondary: colors?.checkout_primary,
            danger: colors?.danger,
            success: colors?.success
            // info: colors?.info,
        }
    }

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
};

export default Theme;