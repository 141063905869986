import { useContext, useEffect } from "react";

import { MobileNoConatiner } from "../Styled/Misc";
import { P1, ErrorText } from "../Styled/Text";
import { Input1 } from "../Styled/Misc";
import { VALIDATION_REGEX, MAX_MIN_CONFIG, ERROR_MSSG } from "../../config/static";

import { InputContext } from "state/Input/Input";

const mobileRegex = new RegExp(VALIDATION_REGEX.MOBILE_NUMBER);

const MobileNo = () => {
    const {mobileNo, setMobileNo, mobileNoError, setMobileNoError} = useContext(InputContext);

    useEffect(() => {
        return () => {
            setMobileNoError('');
            setMobileNo('')
        }
    }, []) // eslint-disable-line

    const handleMobileNoChange = ({ target }) => {
        if (target.value.length > MAX_MIN_CONFIG.MOBILE_MAX_DIGITS) {
            return
        }

        const validateMobile = mobileRegex.test(target.value);
        if (!validateMobile && !mobileNo) {
            setMobileNoError(ERROR_MSSG.MOBILE_INVALID);
            return;
        }
        setMobileNo(target.value);
        setMobileNoError("");
    }

    const handleOnBlur = () => {
        if (!mobileNo) {
            setMobileNoError(ERROR_MSSG.MOBILE_REQUIRED);
            return;
        }

        if (mobileNo.length < MAX_MIN_CONFIG.MOBILE_MIN_DIGITS) {
            setMobileNoError(ERROR_MSSG.MOBILE_INVALID);
            return;
        }
    }

    return (
        <MobileNoConatiner>
            <P1 className="mb-0 pb-2">Mobile Number</P1>
            <Input1
                data-testid="mobile-input"
                type="tel"
                maxLength={MAX_MIN_CONFIG.MOBILE_MAX_DIGITS}
                value={mobileNo}
                onChange={handleMobileNoChange}
                onBlur={handleOnBlur}
                placeholder="Enter Mobile Number"
                isError={!!mobileNoError}
            />
            <ErrorText data-testid='mobile-error'>{mobileNoError}</ErrorText>
        </MobileNoConatiner>
    )
}



export default MobileNo