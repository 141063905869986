import {
  PaymentContainer,
  RightSideContainer
} from "./styled";
import LeftSideContainer from '../../components/Payment/LeftSideContainer/LeftSideContainer.js';
import Footer from '../../components/Footer/Footer';
import RightSideHeader from '../../components/Payment/RightSideHeader/RightSideHeader';

const PaymentCard = ({ children }) => {

  return (
    <>
      <PaymentContainer className='d-md-flex'>
        <LeftSideContainer />
        <RightSideContainer>
          <RightSideHeader />
          {children}
        </RightSideContainer>
      </PaymentContainer>
      <Footer />
    </>
  )
}

export default PaymentCard;